<template>
  <section class="signin-sec">
    <div class="container">
      <div class="signin-bx m-auto">
        <h1>Verify number</h1>
        <p class="text-center">
          We sent a verification code to <b>{{ user.mobile_number }}</b>. Please input the code below.
        </p>
        <form @submit.prevent="verifyCode">
          <div class="form-group">
            <input type="text" v-model="verification_code" placeholder="Verification code" class="form-control" required>
            <span class="error" v-if="errors.verification_code">{{ errors.verification_code }}</span>
          </div>
          <div class="form-group">
            <p><b>Resend code:
              <a href="javascript:;" v-if="isButtonDisabled">
                <span>{{ formattedTime }}</span>
              </a>
              <a href="javascript:;" v-else @click="reSendCode" :class="{disabled: isButtonDisabled}" :disabled="isButtonDisabled">
                <span>Resend</span>
              </a>
            </b></p>
          </div>
          <div class="form-group">
            <div class="g-recaptcha" :data-sitekey="siteKey"></div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn comman-btn-2 w-100">Verify</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<style>
.disabled {
  pointer-events: none;
  color: grey;
}
</style>

<script>
import { mapState } from "vuex";
import { onMounted, ref } from "vue";
import toastr from "toastr";
import { CONSTANT } from "@/constants/constants";

// Define the reCAPTCHA site key
const siteKey = CONSTANT.GOOGLE.SITE_KEY;
const isScriptLoaded = ref(false);

export default {
  data() {
    return {
      siteKey: siteKey,
      verification_code: '',
      errors: {},
      isButtonDisabled: true,
      timer: 300,
      intervalId: null,
    };
  },
  computed: {
    ...mapState(['isAuthenticated', 'user']),
    formattedTime() {
      const minutes = Math.floor(this.timer / 60);
      const seconds = this.timer % 60;
      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
  },
  async created() {
    await this.startTimer();
  },
  mounted() {
    this.loadRecaptcha();
  },
  methods: {
    async reSendCode() {
      if (this.isButtonDisabled) {
        return false;
      }
      this.startTimer();
      try {
        const response = await this.$axios.post('api/code/send', {
          mobile_number: this.user.mobile_number,
        });
        console.log('Code sent:', response.data);
        // Handle success (e.g., show a success message or start a countdown timer)
      } catch (error) {
        console.error('Error sending code:', error);
        // Handle error (e.g., show an error message)
      }
    },
    startTimer() {
      this.isButtonDisabled = true;
      this.timer = 120;

      if (this.intervalId) {
        clearInterval(this.intervalId);
      }

      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer -= 1;
        } else {
          this.isButtonDisabled = false;
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    async verifyCode() {
      try {
        const response = window.grecaptcha.getResponse();

        if (!response.length) {
          toastr.error('reCAPTCHA is not completed', 'error', { timeOut: 3000 });
          return false;
        }
      } catch (error) {
        toastr.error('reCAPTCHA is not completed', 'error', { timeOut: 3000 });
        return false;
      }

      try {
        const response = await this.$axios.post('api/code/verify', {
          mobile_number: this.user.mobile_number,
          code: this.verification_code,
        });

        if (response.data.valid) {
          console.log('Verification success:', response.data);
          await this.markMobileVerified();
          await this.widgetPropetyIdSet();
          this.$router.push('/profile');
        } else {
          // Handle invalid verification
          toastr.error('Invalid verification code', 'error', { timeOut: 3000 });
          console.error('Invalid verification code');
        }
      } catch (error) {
        console.error('Error verifying code:', error);
        this.errors.verification_code = 'Phone number is required.';
      }
    },
    async markMobileVerified() {
      try {
        const response = await this.$axios.post('api/confirm-mobile-number', {
          api_token: localStorage.getItem('userToken'),
          user_id: this.user.id
        });
        this.$store.commit('UPDATE_USER_FIELD', { is_mobile_verified: 1 });
        console.log('Verification success:', response.data);
      } catch (error) {
        console.error('Error verifying code:', error);
        // Handle error (e.g., show an error message)
      }
    },
    async loadRecaptcha() {
      try {
        await loadRecaptchaScript();
        if (window.grecaptcha) {
          window.grecaptcha.render(document.querySelector('.g-recaptcha'), {
            sitekey: this.siteKey,
          });
        }
      } catch (error) {
        console.error('Error loading reCAPTCHA script:', error);
      }
    },
    async widgetPropetyIdSet() {
    const widget_propertyId = localStorage.getItem('widget_propertyId');
    if(widget_propertyId){  
        const token = localStorage.getItem('userToken');      
        this.loading = true;
        try {
            const response = await this.$axios.post('api/widget-property-set-user-id-after-login', {            
              user_id: this.user.id,
              api_token: token,
              property_listing_id: widget_propertyId,
            });
            if (response.data) {
          localStorage.removeItem('widget_propertyId');
              this.$router.push('/my-property/'); 
            }
        } catch (error) {
            this.errorMessage = 'Login failed. Please check your credentials.';
            console.error('Login error:', error);
        } finally {
            this.loading = false;
        }
      }
    },
  }
};

const loadRecaptchaScript = () => {
  return new Promise((resolve, reject) => {
    if (window.grecaptcha) {
      resolve(); // Script already loaded
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;

    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load reCAPTCHA script'));

    document.head.appendChild(script);
  });
};

onMounted(async () => {
  try {
    await loadRecaptchaScript();
    isScriptLoaded.value = true;
  } catch (error) {
    console.error('Error loading reCAPTCHA script:', error);
  }
});
</script>
