<template>    
    
    <section class="property-slider-se">			
        <div class="container">
            <div class="property-slider">
                <div class="property-slider-left">
                    <div class="property-slider-f-slider owl-carousel owl-theme" v-if="propertyImages.length>0">
                        
                        <div class="item" v-for="pimage in propertyImages" :key="pimage.property_listing_id">
                            <div class="property-slider-images">
                                <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${pimage.photo_name}`" :alt="pimage.photo_name" >
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        
                        <div class="item">
                            <div class="property-slider-images">
                                <img  :src="firstImageImgSrc" >
                            </div>
                        </div>
                    </div>

                </div>
                <div class="property-slider-right">
                    <div class="property-slider-thumb">
                        <img  :src="firstImageImgSrc" >
                    </div>
                    <div class="property-slider-thumb mb-0" v-if="propertyImages.length>0">
                        <!-- <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#image-slider">
                            <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${secondImage.photo_name}`" :alt="secondImage.photo_name" href="javascript:;" data-bs-toggle="modal" data-bs-target="#image-slider">
                            <span class="overlay">{{morePhotocount}} photos </span>
                        </a> -->
                        
                            <a  v-for="(image, index) in propertyImages" :key="index" :class="{'d-none': index != 0}" :href="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image.photo_name}`" data-lightbox="gallery" :data-title="image.photo_name">
                                <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image.photo_name}`" :alt="image.photo_name" class="img-fluid">
                                <span class="overlay">{{morePhotocount}} photos </span>
                            </a>
                        
                    </div>
                </div>
            </div>


            <div class="property-details-se">
                <div class="row">
                    <div class="col-md-8">
                        <div class="property-title-se">
                            <h3> {{ propertyDetail.property_name }} <img src="frontend/images/varify-user.svg" alt=""> <img src="frontend/images/file-check.svg" alt=""> </h3>
                            <span class="property-price">S$ {{ propertyDetail.asking_price_sale_rent }}</span>
                        </div>
                        <div class="property-meta-se">
                            <ul>
                                <li><img src="frontend/images/map-gol-ico.svg" alt=""><span><b>{{ propertyDetail.street }}, {{ propertyDetail.area_hdb_estate }}, {{ propertyDetail.district }}</b></span></li>
                                <li><img src="frontend/images/map-gol-ico.svg" alt=""><span><b>listing reference ID: {{ propertyDetail.id }}</b></span></li>
                                <li><img src="frontend/images/map-gol-ico.svg" alt=""><span><b>listing Status: {{ propertyDetail.published_till_date }}</b></span></li>
                            </ul>
                            <ul>
                                <li><a class="btn" href="#"><img src="frontend/images/heart-ico.svg" alt=""></a></li>
                                <li><a class="btn" href="#"><img src="frontend/images/compass-icon.svg" alt=""></a></li>
                                <li><a class="btn" href="#"><img src="frontend/images/share.svg" alt=""></a></li>
                            </ul>
                        </div>
                        <div class="property-details-btn-se">
                            <ul>
                                <li><a  class="active" href="property-signle.html" >Property details</a></li>
                                <li><a href="property-signle-feng-shui.html">Feng Shui</a></li>
                            </ul>
                        </div>



                        <div class="accordion custom-accordion" id="accordion-one">
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading1">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#key-information" aria-expanded="true" aria-controls="key-information">
                                        Key information
                                    </button>
                                </div>
                                <div id="key-information" class="accordion-collapse collapse show" aria-labelledby="heading1" >
                                    <div class="accordion-body">
                                        <div class="row row-cols-1 row-cols-sm-4">
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Listing type</span>
                                                    <h5>{{ getLabelByValue(''+propertyDetail.listing_type+'') }}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Property Address</span>
                                                    <h5>{{propertyDetail.district}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Street</span>
                                                    <h5>{{propertyDetail.street}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Area / HDB Estate</span>
                                                    <h5>{{propertyDetail.area_hdb_estate}}  SQF</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading2">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#basic-information" aria-expanded="true" aria-controls="basic-information">
                                        Basic information
                                    </button>
                                </div>
                                <div id="basic-information" class="accordion-collapse collapse" aria-labelledby="heading2" >
                                    <div class="accordion-body">
                                        <div class="row row-cols-1 row-cols-sm-4">
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Property Type</span>
                                                    <h5>{{ getPropertyType(propertyDetail.property_type) }} </h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Land Tenure</span>
                                                    <h5>{{propertyDetail.land_tenure}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Floor Area ({{propertyDetail.floor_area_unit}})</span>
                                                    <h5>{{propertyDetail.floor_area}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span>Land Area</span>
                                                    <h5>{{propertyDetail.land_area}} {{propertyDetail.land_area_unit}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading3">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#configuration" aria-expanded="true" aria-controls="configuration">
                                        Configuration
                                    </button>
                                </div>
                                <div id="configuration" class="accordion-collapse collapse" aria-labelledby="heading3" >
                                    <div class="accordion-body">
                                        <div class="row row-cols-1 row-cols-sm-4">
                                            <div class="col">
                                                <div class="light-info-box text-center">
                                                    <img src="frontend/images/room-icon.svg" alt="">
                                                    <span class="d-inline-block w-100">Rooms</span>
                                                    <h5>{{propertyDetail.no_of_rooms}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box text-center">
                                                    <img src="frontend/images/bathrooms-icon.svg" alt="">
                                                    <span class="d-inline-block w-100">Bathrooms</span>
                                                    <h5>{{propertyDetail.no_of_baths}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box text-center">
                                                    <img src="frontend/images/carpark-lots-icon.svg" alt="">
                                                    <span class="d-inline-block w-100">Carpark lots</span>
                                                    <h5>{{propertyDetail.no_car_park_lots}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box text-center">
                                                    <img src="frontend/images/unit-type-icon.svg" alt="">
                                                    <span class="d-inline-block w-100">Unit Type</span>
                                                    <h5>{{propertyDetail.unit_no}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading4">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#property-details" aria-expanded="true" aria-controls="property-details">
                                    Property Details
                                    </button>
                                </div>
                                <div id="property-details" class="accordion-collapse collapse" aria-labelledby="heading4" >
                                    <div class="accordion-body">
                                        <div class="accordion custom-accordion-2" id="accordion-two">
                                            <div class="accordion-item">
                                                <div class="accordion-header" id="heading-1">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#key-features" aria-expanded="true" aria-controls="key-features">
                                                        Key features
                                                    </button>
                                                </div>
                                                <div id="key-features" class="accordion-collapse collapse show" aria-labelledby="heading-1" data-bs-parent="#accordion-two">
                                                    <div class="accordion-body">
                                                        <ul class="key-features-green-check">
                                                            <li  v-for="option in featuresList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                                                <img src="frontend/images/green-check.svg" alt=""> {{option.additional_feature_name}}</li>

                                                            <li  v-for="option in add_featuresList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                                                    <img src="frontend/images/green-check.svg" alt=""> {{option.additional_feature_name}}</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <div class="accordion-header" id="heading-1">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#facilities" aria-expanded="true" aria-controls="facilities">
                                                        Facilities
                                                    </button>
                                                </div>
                                                <div id="facilities" class="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordion-two">
                                                    <div class="accordion-body">
                                                        <div class="row row-cols-1 row-cols-sm-4">
                                                            
                                                            <div class="col" v-for="option in facilitiyList" :key="option.pr_master_facilty_id" :value="option.pr_master_facilty_id">
                                                                <div class="light-info-box text-center">
                                                                    <img src="frontend/images/ac-icon.svg" alt="">																		
                                                                    <h5>{{option.additional_facility_name}}</h5>
                                                                </div>
                                                            </div>

                                                            <div class="col" v-for="option in add_facilitiyList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                                                <div class="light-info-box text-center">
                                                                    <img src="frontend/images/ac-icon.svg" alt="">																		
                                                                    <h5>{{option.additional_facility_name}}</h5>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                            
                                            <!-- <div class="accordion-item">
                                                <div class="accordion-header" id="heading-1">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#add_features" aria-expanded="true" aria-controls="add_features">
                                                        Additional Features
                                                    </button>
                                                </div>
                                                <div id="add_features" class="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordion-two">
                                                    <div class="accordion-body">
                                                        <div class="row row-cols-1 row-cols-sm-4">
                                                            
                                                            <div class="col" v-for="option in add_featuresList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                                                <div class="light-info-box text-center">
                                                                    <img src="frontend/images/ac-icon.svg" alt="">																		
                                                                    <h5>{{option.additional_feature_name}}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <!-- <div class="accordion-item">
                                                <div class="accordion-header" id="heading-1">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#add_facilities" aria-expanded="true" aria-controls="add_facilities">
                                                        Additional Facilities
                                                    </button>
                                                </div>
                                                <div id="add_facilities" class="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordion-two">
                                                    <div class="accordion-body">
                                                        <div class="row row-cols-1 row-cols-sm-4">
                                                            
                                                            <div class="col" v-for="option in add_facilitiyList" :key="option.pr_master_feature_id" :value="option.pr_master_feature_id">
                                                                <div class="light-info-box text-center">
                                                                    <img src="frontend/images/ac-icon.svg" alt="">																		
                                                                    <h5>{{option.additional_facility_name}}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading5">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#property-status" aria-expanded="true" aria-controls="property-status">
                                    Property Status
                                    </button>
                                </div>
                                <div id="property-status" class="accordion-collapse collapse" aria-labelledby="heading5" >
                                    <div class="accordion-body">
                                        <div class="row row-cols-1 row-cols-sm-4">
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Current Occupancy</span>
                                                    <h5>{{propertyDetail.current_occupancy}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Current Mthly Rental Rate</span>
                                                    <h5>{{propertyDetail.current_tenancy_rental_rate}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Current Tenancy Expiry</span>
                                                    <h5>{{propertyDetail.current_tenancy_expiration_date}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Availability Date</span>
                                                    <h5>{{propertyDetail.availability_date}}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading6">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricing-terms" aria-expanded="true" aria-controls="pricing-terms">
                                    Pricing Terms & Payment Options <i class="fa fa-question-circle ms-3" data-bs-toggle="tooltip" data-bs-title="Pricing Terms & Payment Options"></i>
                                    </button>
                                </div>
                                <div id="pricing-terms" class="accordion-collapse collapse" aria-labelledby="heading6" >
                                    <div class="accordion-body">
                                        <div class="light-info-box">
                                            <span class="d-inline-block w-100">Listing Price</span>
                                            <h5>S${{ propertyDetail.asking_price_sale_rent }}  <small>(S$1,000/SQF - S$1,200/SQF)</small></h5>
                                        </div>
                                        <div class="comman-title mt-4">
                                            <h4>Payment Mode Acceptance</h4>
                                        </div>
                                        <div class="property-meta-se mb-0">
                                            <ul>
                                                <li v-for="option in mode_of_payment" :key="option"><span>{{option}}</span></li>
                                                <!-- <li><img src="frontend/images/map-gol-ico.svg" alt=""><span>listing reference ID: 61506</span></li> -->
                                                <!-- <li><img src="frontend/images/map-gol-ico.svg" alt=""><span>listing Status: May 1, 2023</span></li> -->
                                            </ul>                                            
                                        </div>
                                        <div class="row row-cols-1 row-cols-sm-4">
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Rental Period / Preferred Tenancy (If Applicable)</span>
                                                    <h5>{{ getRentalPeriod(propertyDetail.rental_period_preferred_tenancy)}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Security Deposit</span>
                                                    <h5>{{propertyDetail.rental_security_deposit}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Utility Deposit</span>
                                                    <h5>{{propertyDetail.rental_utility_deposit}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Lump-Sum Pre-Pay Rent</span>
                                                    <h5>{{propertyDetail.rental_lumpsum_pre_pay_in_months_rent}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Proposed Pre-Pay Rebate</span>
                                                    <h5>{{propertyDetail.rental_proposed_pre_pay_rebate_in_percentage}}</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Diversity Friendly</span>
                                                    <h5 v-if="propertyDetail.rental_diversity_friendly">All renters are welcome regardless of race, ethnicity, religion, age, gender identity, sexual orientation or physical ability.</h5>
                                                    <h5 v-if="!propertyDetail.rental_diversity_friendly">NO</h5>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Pet Policy</span>
                                                    <h5 v-if="propertyDetail.rental_pet_allowed == 1">Pets Allowed</h5>
                                                    <h5 v-if="propertyDetail.rental_pet_allowed == 2">Pets Not-Allowed</h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading7">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Hybrid-Listing" aria-expanded="true" aria-controls="Hybrid-Listing">
                                    Hybrid-Listing
                                    </button>
                                </div>
                                <div id="Hybrid-Listing" class="accordion-collapse collapse" aria-labelledby="heading7" >
                                    <div class="accordion-body">
                                        <div class="row align-items-center">
                                            <div class="col-4">
                                                <div class="light-info-box ">
                                                    <span class="d-inline-block w-100 mt-3" v-if="propertyDetail.hybrid_listing_type==1">Attend To Inquiry By Myself</span>												
                                                    <span class="d-inline-block w-100 mt-3" v-if="propertyDetail.hybrid_listing_type==2">Assign To Recommended Estate Agent</span>												
                                                    <span class="d-inline-block w-100 mt-3" v-if="propertyDetail.hybrid_listing_type==3">Find My Own/Co-Broking Estate Agent</span>												
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="light-info-box">
                                                    <span class="d-inline-block w-100">Contact Information</span>
                                                    <div class="d-flex" v-if="propertyDetail.display_my_contact_information">
                                                        <h5 class="me-2"><img src="frontend/images/email-icon.svg" alt=""> {{propertyUserDetail.email}}</h5>
                                                        <h5 class="me-2"><img src="frontend/images/phone-icon.svg" alt=""> {{propertyUserDetail.mobile_number}}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading8">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Auto-Map" aria-expanded="true" aria-controls="Auto-Map">
                                    Nearby Amenities (Auto-Map)
                                    </button>
                                </div>
                                <div id="Auto-Map" class="accordion-collapse collapse" aria-labelledby="heading8" >
                                    <div class="accordion-body">
                                        <div class="row row-cols-1 row-cols-sm-3">
                                            <div class="col">
                                                <div class="light-info-box text-center">
                                                    <img src="frontend/images/Nearest-MRT-icon.svg" alt="">
                                                    <h5>500M to</h5>
                                                    <span class="d-inline-block w-100">Nearest MRT</span>												
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box text-center">
                                                    <img src="frontend/images/Nearest-Bus-Station-icon.svg" alt="">
                                                    <h5>100M to</h5>
                                                    <span class="d-inline-block w-100">Nearest Bus Station</span>												
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="light-info-box text-center">
                                                    <img src="frontend/images/Nearest-School-icon.svg" alt="">
                                                    <h5>3KM to</h5>
                                                    <span class="d-inline-block w-100">Nearest School</span>												
                                                </div>
                                            </div>
                                        </div>											
                                    </div>
                                </div>
                            </div>
                            
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading9">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Additional-Links" aria-expanded="true" aria-controls="Additional-Links">
                                    Additional Links/Attachments
                                    </button>
                                </div>
                                <div id="Additional-Links" class="accordion-collapse collapse" aria-labelledby="heading9" >
                                    <div class="accordion-body">
                                        <div class="row row-cols-1 row-cols-sm-3" v-if="otherDocList.length>0">
                                            <div class="col form-group" v-for="option in otherDocList" :key="option.id" :value="option.id" >
                                                <a :href="`${CONSTANT.IMAGE_URL}/imagesPropertyDocumentAndOtherPhotos/${option.photo_name}`" class="comman-btn-6" target="_blank"> 
                                                    {{option.photo_original_name}} 
                                                    <img src="frontend/images/upload-orange.svg" alt=""></a>
                                            </div>
                                        </div>											
                                        <div class="comman-title mt-4"><h4 data-v-2c4228aa="">Media link</h4></div>
                                        <div class="row row-cols-1 row-cols-sm-3">
                                            <div class="col form-group" v-for="option in mediaLinksList" :key="option.id" :value="option.id" >
                                                <a :href="`${option.link_url}`" class="comman-btn-6" target="_blank"> 
                                                    {{option.link_name}} ( {{option.link_type}} )
                                                    <img src="frontend/images/upload-orange.svg" alt=""></a>
                                            </div>
                                        </div>											
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading10">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Descriptions" aria-expanded="true" aria-controls="Descriptions">
                                    Descriptions
                                    </button>
                                </div>
                                <div id="Descriptions" class="accordion-collapse collapse" aria-labelledby="heading10" >
                                    <div class="accordion-body">
                                        {{propertyDetail.additional_description}}
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <div class="accordion-header" id="heading11">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Reviews" aria-expanded="true" aria-controls="Reviews">
                                    Reviews
                                    </button>
                                </div>
                                <div id="Reviews" class="accordion-collapse collapse" aria-labelledby="heading11" >
                                    <div class="accordion-body">
                                        <div class="user-review-box">
                                            <div class="profile-box">
                                                <img src="frontend/images/user-photo.png" alt="">
                                                <div class="">
                                                    <h5>Brooklyn Simmons</h5>
                                                    <ul class="review">
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p>Lovely room in the hostess's apartment. Annabella is accommodating and straightforward, I felt completely free in her four walls. Good location for a stay in Copenhagen.</p>
                                        </div>
                                        <div class="user-review-box">
                                            <div class="profile-box">
                                                <img src="frontend/images/user-photo.png" alt="">
                                                <div class="">
                                                    <h5>Brooklyn Simmons</h5>
                                                    <ul class="review">
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                        <li><img src="frontend/images/star.svg" alt=""></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p>Lovely room in the hostess's apartment. Annabella is accommodating and straightforward, I felt completely free in her four walls. Good location for a stay in Copenhagen.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="review-form">
                                <div class="comman-title">
                                    <h3>Leave a review</h3>
                                </div>
                                <div class="review-form-box">
                                    <form>
                                        <div class="form-group d-flex align-items-center">
                                            <label class="w-auto me-3"><b>Your Rating</b></label>
                                            <div class="rating">
                                                <input type="radio" name="rating" value="5" id="5">
                                                <label for="5"><i class="fa fa-star-o"></i></label>
                                                
                                                <input type="radio" name="rating" value="4" id="4">
                                                <label for="4"><i class="fa fa-star-o"></i></label>
                                                
                                                <input type="radio" name="rating" value="3" id="3">
                                                <label for="3"><i class="fa fa-star-o"></i></label> 
                                                
                                                <input type="radio" name="rating" value="2" id="2">
                                                <label for="2"><i class="fa fa-star-o"></i></label> 
                                                
                                                <input type="radio" name="rating" value="1" id="1">
                                                <label for="1"><i class="fa fa-star-o"></i></label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Full name">
                                        </div>
                                        <div class="form-group">
                                            <textarea class="form-control" rows="5" placeholder="Writer message here"></textarea>
                                        </div>
                                        <button class="comman-btn-2 w-100">Submit</button>
                                    </form>
                                </div>
                            </div>


                            <div class="accordion-item">
                                <div class="accordion-header" id="heading12">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Disclaimer" aria-expanded="true" aria-controls="Disclaimer">
                                    Disclaimer 
                                    </button>
                                </div>
                                <div id="Disclaimer" class="accordion-collapse collapse" aria-labelledby="heading12" >
                                    <div class="accordion-body">
                                        <p>This property listing may include features and content enhanced by Feng Shui insights, Hybrid listings, and AI-generated content. These elements are provided for general informational purposes only and should not be taken as expert advice. Property details and recommendations based on Feng Shui are not to be construed as a guarantee of increased value, prosperity, or suitability. Users are encouraged to conduct their own due diligence and consult with professionals where appropriate.”</p>
                                    </div>
                                </div>
                            </div>

                            <div class="map-se">
                                <div class="comman-title">
                                    <h3>Map</h3>
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.33750346623!2d-73.97968099999999!3d40.6974881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1723815204801!5m2!1sen!2sin" width="100%" height="325px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <div class="accordion-item">
                                <div class="accordion-header" id="heading13">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Transaction-history" aria-expanded="true" aria-controls="Transaction-history">
                                    Transaction history 
                                    </button>
                                </div>
                                <div id="Transaction-history" class="accordion-collapse collapse" aria-labelledby="heading13" >
                                    <div class="accordion-body">
                                        <div class="table-responsive transaction-history-table">
                                            <table class="">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Block</th>
                                                        <th>Unit</th>
                                                        <th>beds</th>
                                                        <th>PSF</th>
                                                        <th>Area(sqft)</th>
                                                        <th>Price sold</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>12/2023</td>
                                                        <td>34</td>
                                                        <td>#0x-xx</td>
                                                        <td>3 BR</td>
                                                        <td>$1,234</td>
                                                        <td>1200</td>
                                                        <td>$1,234</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12/2023</td>
                                                        <td>34</td>
                                                        <td>#0x-xx</td>
                                                        <td>3 BR</td>
                                                        <td>$1,234</td>
                                                        <td>1200</td>
                                                        <td>$1,234</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12/2023</td>
                                                        <td>34</td>
                                                        <td>#0x-xx</td>
                                                        <td>3 BR</td>
                                                        <td>$1,234</td>
                                                        <td>1200</td>
                                                        <td>$1,234</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12/2023</td>
                                                        <td>34</td>
                                                        <td>#0x-xx</td>
                                                        <td>3 BR</td>
                                                        <td>$1,234</td>
                                                        <td>1200</td>
                                                        <td>$1,234</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12/2023</td>
                                                        <td>34</td>
                                                        <td>#0x-xx</td>
                                                        <td>3 BR</td>
                                                        <td>$1,234</td>
                                                        <td>1200</td>
                                                        <td>$1,234</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12/2023</td>
                                                        <td>34</td>
                                                        <td>#0x-xx</td>
                                                        <td>3 BR</td>
                                                        <td>$1,234</td>
                                                        <td>1200</td>
                                                        <td>$1,234</td>
                                                    </tr>
                                                    <tr>
                                                        <td>12/2023</td>
                                                        <td>34</td>
                                                        <td>#0x-xx</td>
                                                        <td>3 BR</td>
                                                        <td>$1,234</td>
                                                        <td>1200</td>
                                                        <td>$1,234</td>
                                                    </tr>
                                                </tbody>
                                            </table>	
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                            

                    </div>
                    <div class="col-md-4">
                        <div class="property-siderbar-boxs">
                            <div class="profile-box">
                                <img :src="computedProfileImgSrc" >
                                <div>
                                    <h5>{{propertyUserDetail.name}} <img src="frontend/images/varify-user.svg" alt=""></h5>
                                    <span>Singapore</span>
                                </div>
                            </div>
                            <div class="list-box">
                                <ul>
                                    <li v-if="user_service_details">Position: <span><b>{{user_service_details.position_held}}</b></span></li>
                                    <li v-if="user_service_details">CEA Registration No. <span><b>000000000</b></span></li>
                                    <li v-if="user_service_details">Agency Name <span><b>{{propertyUserDetail.agency_name}}</b></span></li>
                                    <li v-if="user_service_details">Agency License no. <span><b>{{user_service_details.agency_license}}</b></span></li>
                                    <li>Mobile Number:<span><b>{{ maskedNumber(propertyUserDetail.mobile_number) }}</b></span></li>
                                    <!-- <li>Email:<span><b>{{propertyUserDetail.email}}</b></span></li> -->
                                </ul>
                            </div>
                            <div class="property-siderbar-2-btn">
                                <a href="#" class="comman-btn-2 w-100"><b>View my properties</b></a>
                                <a href="#" class="comman-btn-2 w-100"><b>Request now</b></a>
                            </div>
                        </div>

                        <div class="property-siderbar-boxs">
                            <div class="">
                                <a href="#" class="comman-btn-2 w-100"><b>Your Favorable Directions</b></a>									
                            </div>
                            <div class="list-box">
                                <ul>
                                    <li><b>Your Life Palace</b> <span><b>Sectors/Direction</b></span></li>
                                    <li>Health & Wealth Retention  <span>• W</span></li>
                                    <li>Success & Prosperity <span>• NE</span></li>
                                    <li>Harmony & Relationship <span>• NW</span></li>
                                    <li>Personal Growth& Peace<span>• SW</span></li>
                                </ul>
                            </div>
                            <div class="mt-3">
                                <a href="#" class="comman-btn-2 w-100"><b>Personal Affinity Stars</b></a>									
                            </div>
                            <div class="list-box">
                                <table class="">
                                    <tr>
                                        <td><b>StarsAffinity</b></td>
                                        <td><b>Zodiac</b></td>
                                        <td><b>Sectors</b></td>
                                    </tr>
                                    <tr>
                                        <td>Nobleman</td>
                                        <td>• Goat</td>
                                        <td>• SW1</td>
                                    </tr>
                                    <tr>
                                        <td>Nobleman</td>
                                        <td>• Ox</td>
                                        <td>• SW1</td>
                                    </tr>
                                    <tr>
                                        <td>Intelligence</td>
                                        <td>• Boar</td>
                                        <td></td>
                                    </tr>										
                                </table>
                            </div>
                        </div>
                        <div class="property-siderbar-boxs">
                            <div class="comman-title">
                                <h3>Hybrid listing</h3>
                            </div>
                            <p>{{propertyUserDetail.name}} at <span v-if="user_service_details">{{user_service_details.agency_name}}</span>"  <span v-if="user_service_details"> ({{propertyUserDetail.first_name}}, {{propertyUserDetail.last_name}}, {{user_service_details.agency_name}}) </span></p>
                            <div class="mt-3">
                                <a href="#" class="comman-btn-2 w-100"><b>Create a hybrid listing</b></a>									
                            </div>
                        </div>
                        <div class="property-siderbar-boxs">
                            <div class="comman-title">
                                <h3>Service Request </h3>
                            </div>
                            <p>{{propertyUserDetail.name}} at <span v-if="user_service_details">{{user_service_details.agency_name}}</span>"  <span v-if="user_service_details"> ({{propertyUserDetail.first_name}}, {{propertyUserDetail.last_name}}, {{user_service_details.agency_name}}) </span></p>
                            <div class="mt-3">
                                <a href="#" class="comman-btn-2 w-100"><b>Service Request</b></a>									
                            </div>
                        </div>
                        <div class="property-siderbar-boxs">
                            <div class="comman-title">
                                <h3>Auspicious dates </h3>
                            </div>
                            <div class="calendar">
                                <header>
                                    <pre class="left"><i class="fa fa-angle-left"></i></pre>									  
                                    <div class="header-display">
                                    <p class="display mb-0">""</p>
                                    </div>								
                                    <pre class="right"><i class="fa fa-angle-right"></i></pre>								
                                </header>
                            
                                <div class="week">
                                    <div>S</div>
                                    <div>M</div>
                                    <div>T</div>
                                    <div>W</div>
                                    <div>T</div>
                                    <div>F</div>
                                    <div>S</div>
                                </div>
                                <div class="days"></div>
                                </div>
                                <div class="display-selected">
                                <p class="selected"></p>
                                </div>

                                <div class="lrm-czh-se">
                                <div class=""> 
                                    Legend 
                                    <a href="javascript:;" class="comman-btn-3"><b>Renovation</b></a>
                                    <a href="javascript:;" class="comman-btn-2"><b>Move in</b></a>
                                </div>
                                <div class=""> 
                                    Clashes
                                    <select class="form-control">
                                        <option>Zodiac Sign</option>
                                    </select>
                                    <select class="form-control">
                                        <option>House Direction</option>
                                    </select>
                                    </div>

                                </div>

                        </div>
                        <div class="property-siderbar-boxs">
                            <div class="comman-title">
                                <h3>Schedule tour </h3>
                            </div>
                            <p>{{ propertyDetail.street }}, {{ propertyDetail.area_hdb_estate }}, {{ propertyDetail.district }}</p>
                            <form>
                                <div class="form-group">
                                    <input type="text" placeholder="Full name" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Email address" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="text" placeholder="Phone number" class="form-control">
                                </div>
                                <div class="form-group">
                                    <textarea placeholder="Writer message here" class="form-control"></textarea>
                                </div>				
                                <div class="form-group">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" value="" id="Are-you-agree">
                                        <label class="form-check-label" for="Are-you-agree"><b>Are you agree?</b></label>
                                    </div>
                                </div>
                                <button class="comman-btn-2 w-100"><b>Interested</b></button>
                            </form>
                        </div>
                        <div class="property-siderbar-boxs" v-if="propertyDetail.listing_type == 1 || propertyDetail.listing_type == 4 || propertyDetail.listing_type == 5 || propertyDetail.listing_type == 6 ">
                            <div class="comman-title">
                                <h3>Mortgage calculator</h3>
                            </div>
                            <p>{{ propertyDetail.street }}, {{ propertyDetail.area_hdb_estate }}, {{ propertyDetail.district }}</p>
                            <div class="calculator-mortgage">
                                <div class="form-group">
                                    <label class=""><b>Property value</b></label>
                                    <input type="text" class="form-control w-100" placeholder="Property value"  :value="'S$'+propertyDetail.asking_price_sale_rent">
                                </div>
                                <div class="form-group">
                                    <label class=""><b>Loan Amount</b></label>																														
                                    <div class="range-item">												
                                            <div class="range-input d-flex position-relative">
                                                <input type="range" min="0" max="10" class="form-range" name="dataShared" value="14" />
                                                <div class="range-line">
                                                    <span class="active-line"></span>
                                                </div>
                                                <div class="dot-line">
                                                    <span class="active-dot"></span>
                                                </div>
                                            </div>
                                            <ul class="list-inline list-unstyled">
                                                <li class="list-inline-item">
                                                    <span>10</span>
                                                </li>
                                                <li class="list-inline-item">
                                                    <span>20</span>
                                                </li>
                                                <li class="list-inline-item">
                                                    <span>30</span>
                                                </li> 
                                                <li class="list-inline-item">
                                                    <span></span>
                                                </li> 
                                                <li class="list-inline-item">
                                                    <span>75</span>
                                                </li> 
                                            </ul>									
                                    </div>
                                    <input type="text" class="form-control text-center" placeholder="Loan Amount" value="75%">
                                </div>
                                <div class="form-group">
                                    <label class=""><b>Tenure (years)</b></label>										
                                    <div class="range-item">												
                                        <div class="range-input d-flex position-relative">
                                            <input type="range" min="0" max="10" class="form-range" name="dataShared" value="6" />
                                            <div class="range-line">
                                                <span class="active-line"></span>
                                            </div>
                                            <div class="dot-line">
                                                <span class="active-dot"></span>
                                            </div>
                                        </div>
                                        <ul class="list-inline list-unstyled">
                                            <li class="list-inline-item">
                                                <span>4</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>10</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>30</span>
                                            </li> 
                                            <li class="list-inline-item">
                                                <span></span>
                                            </li> 
                                            <li class="list-inline-item">
                                                <span>35</span>
                                            </li> 
                                        </ul>
                                    </div>																					
                                    <input type="text" class="form-control text-center" placeholder="Tenure (years)" value="30 yrs">
                                </div>
                                <div class="form-group">
                                    <label class=""><b>Interest Rate</b></label>
                                    <div class="range-item">												
                                        <div class="range-input d-flex position-relative">
                                            <input type="range" min="0" max="10" class="form-range" name="dataShared" value="0" />
                                            <div class="range-line">
                                                <span class="active-line"></span>
                                            </div>
                                            <div class="dot-line">
                                                <span class="active-dot"></span>
                                            </div>
                                        </div>
                                        <ul class="list-inline list-unstyled">
                                            <li class="list-inline-item">
                                                <span>0.5</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>1</span>
                                            </li>
                                            <li class="list-inline-item">
                                                <span>3</span>
                                            </li> 
                                            <li class="list-inline-item">
                                                <span></span>
                                            </li> 
                                            <li class="list-inline-item">
                                                <span>6</span>
                                            </li> 
                                        </ul>
                                    </div>
                                    <input type="text" class="form-control text-center" placeholder="Interest Rate" value="0.50%">
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header">
                                    <h5>your mortgage breakdown</h5>
                                </div>
                                <div class="card-body">
                                    <div class="row row-cols-md-2">
                                        <div class="col">
                                            <div class="chart-box">
                                                <span class="mb-0">Loan amount (75%)</span>
                                                <p class="mb-2">S$ 37,500</p>
                                                <div class="circal-chart">
                                                    <img src="frontend/images/downpayment-circal.svg" alt="">
                                                    <p>S$ 12,500</p>
                                                    <span>Downpayment</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="chart-box">
                                                <p class="mb-0">Monthly Repayment</p>
                                                <span class="mb-2"> <img src="frontend/images/yellow-dot.png" alt=""> S$ 96 Principal <img src="frontend/images/blue-dot.png" alt="">  S$ 15 Int</span>
                                                <div class="circal-chart">
                                                    <img src="frontend/images/month-circal.svg" alt="">
                                                    <p>S$ 112</p>
                                                    <span>/month</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>


    <section class="offer-pro-sec d-none">
        <div class="container">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">						
                    <h3>Similar Listings</h3>
                </div>
                <!-- <div class="readmore ms-md-auto">
                    <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                </div> -->
            </div>
            <div class="row row-cols-1 row-cols-md-3">
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-5.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-6.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-7.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>				
            </div>
        </div>
    </section>

    <section class="recnet-list-sec d-none">
        <div class="container">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">						
                    <h3>Nearby Locations</h3>
                </div>
                <!-- <div class="readmore ms-md-auto">
                    <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                </div> -->
            </div>
            <div class="row row-cols-1 row-cols-md-3">
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-5.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-6.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-7.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                    
            </div>
        </div>
    </section>

    <section class="offer-pro-sec d-none">
        <div class="container">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">						
                    <h3>listings by same agent/advertiser</h3>
                </div>
                <!-- <div class="readmore ms-md-auto">
                    <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                </div> -->
            </div>
            <div class="row row-cols-1 row-cols-md-3">
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-5.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-6.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-7.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>				
            </div>
        </div>
    </section>

    <section class="recnet-list-sec d-none">
        <div class="container">
            <div class="comman-title d-md-flex align-items-center justify-content-between">
                <div class="offer-heading">						
                    <h3>similar Property Type (price/size/range)</h3>
                </div>
                <!-- <div class="readmore ms-md-auto">
                    <a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
                </div> -->
            </div>
            <div class="row row-cols-1 row-cols-md-3">
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-5.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-6.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="project-bx">
                        <div class="heart-pro">
                            <a href=""><i class="fa fa-heart-o"></i></a>
                        </div>
                        <picture>
                            <img src="frontend/images/property-7.jpg">
                        </picture>
                        <div class="project-info">
                            <div class="d-md-flex mb-2 align-items-center">
                                <h3><a href="">Property name</a></h3>
                                <div class="price-pro ms-md-auto">
                                    <span><small>s$</small> 3000-40,000</span>
                                </div>
                            </div>
                            <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
                                <div class="pro-specify">
                                    <span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
                                    <span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
                                    <span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
                                </div>
                                <div class="pro-tag">
                                    <a href="#">De</a>
                                    <a href="#">Condo</a>
                                    <a href="#">Freehold</a>
                                </div>
                                <ul>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico1.png">
                                    </figure>
                                    <small>Direct<br>Owner</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico2.png">
                                    </figure>
                                    <small>SingPass<br>Verified </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico3.png">
                                    </figure>
                                    <small>Documents<br>Verified</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico4.png">
                                    </figure>
                                    <small>Fengshui<br>Map</small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/specify-ico5.png">
                                    </figure>
                                    <small>Hybrid-Listing<br>Owners </small>
                                </li>
                                <li>
                                    <figure>
                                        <img src="frontend/images/Down-arrow.svg">
                                    </figure>
                                    <small>BMV</small>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                    
            </div>
        </div>
    </section>


    <!-- <div class="modal fade otp" id="image-slider" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
				<div class="modal-body">


                    <div class="col-md-4" v-for="(image, index) in propertyImages" :key="index">
                        <a v-if="index==0" :href="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image.photo_name}`" data-lightbox="gallery" :data-title="image.photo_name">
                            <img :src="`${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image.photo_name}`" :alt="image.photo_name" class="img-fluid">
                        </a>
                    </div>
				</div>
			</div>
		</div>
	</div> -->


</template>
  
  <script>
  import { CONSTANT } from '@/constants/constants';
  import { OPTIONS_GROUP_1,PROPERTY_TYPE,RENTAL_PERIOD } from '@/constants/constantsProperty';
  import { mapState } from 'vuex';
  // Import Lightbox2 CSS and JS
  import 'lightbox2/dist/css/lightbox.min.css';
  import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';
 
  export default {
    name: 'PropertyDetail', 
    data() {
        return {
            otherDocList: [],
            mediaLinksList: [],
            rentalPriodLIst: RENTAL_PERIOD,
            CONSTANT,
            listingType: OPTIONS_GROUP_1,
            propertyType: PROPERTY_TYPE,
            propertyDetail : [''],
            propertyUserDetail : [],
            user_service_details : [],
            propertyImages : [],
            morePhotocount : 0,
            firstImage : [],
            secondImage : [],
            featuresList : [],
            add_featuresList : [],
            facilitiyList : [],
            add_facilitiyList : [],
            mode_of_payment:[]
        }
    },
    mounted() {
        
        let display = document.querySelector(".display");
		let days = document.querySelector(".days");
		let previous = document.querySelector(".left");
		let next = document.querySelector(".right");
		let selected = document.querySelector(".selected");

		let date = new Date();

		let year = date.getFullYear();
		let month = date.getMonth();

		function displayCalendar() {
			const firstDay = new Date(year, month, 1);
			const lastDay = new Date(year, month + 1, 0);

			const firstDayIndex = firstDay.getDay();
			const numberOfDays = lastDay.getDate();

			let formattedDate = date.toLocaleString("en-US", {
				month: "long",
				year: "numeric"
			});

			display.innerHTML = `${formattedDate}`;

			days.innerHTML = ''; // Clear days before repopulating

			for (let x = 0; x < firstDayIndex; x++) {
				const div = document.createElement("div");
				days.appendChild(div);
			}

			for (let i = 1; i <= numberOfDays; i++) {
				let div = document.createElement("div");
				let currentDate = new Date(year, month, i);

				div.dataset.date = currentDate.toDateString();
				div.innerHTML = i;

				if (
					currentDate.getFullYear() === new Date().getFullYear() &&
					currentDate.getMonth() === new Date().getMonth() &&
					currentDate.getDate() === new Date().getDate()
				) {
					div.classList.add("current-date");
				}

				days.appendChild(div);
			}
		}

		function displaySelected() {
			const dayElements = document.querySelectorAll(".days div");
			dayElements.forEach((day) => {
				day.addEventListener("click", (e) => {
					// Remove previously selected date class
					const previouslySelected = document.querySelector(".selected-date");
					if (previouslySelected) {
						previouslySelected.classList.remove("selected-date");
					}

					// Add selected date class
					const selectedDate = e.target;
					selectedDate.classList.add("selected-date");

					selected.innerHTML = `Selected Date : ${selectedDate.dataset.date}`;
				});
			});
		}
       
		// Call the function to display the calendar
		displayCalendar();

		previous.addEventListener("click", () => {
			month--;
			if (month < 0) {
				month = 11;
				year--;
			}
			date.setFullYear(year);
			date.setMonth(month);
			displayCalendar();
			displaySelected();
		});

		next.addEventListener("click", () => {
			month++;
			if (month > 11) {
				month = 0;
				year++;
			}
			date.setFullYear(year);
			date.setMonth(month);
			displayCalendar();
			displaySelected();
		});

		// Initialize the calendar and selected date display
		displaySelected();	
		
        

        
    },
    created() {
        this.fetchOptions();
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        },
        computedProfileImgSrc() {
            return this.propertyUserDetail.profile_photo_name ? `${CONSTANT.IMAGE_URL}/imagesProfile/${this.propertyUserDetail.profile_photo_name}` : CONSTANT.DEFAULT_IMAGE;
        },
        firstImageImgSrc() {
            if(this.firstImage){
                return this.firstImage.photo_name ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${this.firstImage.photo_name}` : CONSTANT.DEFAULT_NO_IMAGE;
            }else{
                return CONSTANT.DEFAULT_NO_IMAGE;
            }
        }
        
    },
    methods: {
      
        async fetchOptions() {           
            const token = localStorage.getItem('userToken');
            try {
                const response = await this.$axios.post('api/property-detail',
                {                    
                    user_id :this.user.id,
                    api_token: token,
                    property_listing_id:this.itemId,                    
                });
                this.propertyDetail = response.data.output[0];
                this.propertyUserDetail = response.data.output[0].user_id_details;
                this.user_service_details = response.data.output[0].user_service_details;
                this.propertyImages = response.data.output[0].actual_unit_photo;
                this.morePhotocount = response.data.output[0].actual_unit_photo.length;
                this.firstImage = response.data.output[0].actual_unit_photo[1];
                this.secondImage = response.data.output[0].actual_unit_photo[2];
                this.featuresList = response.data.output[0].features;
                this.add_featuresList = response.data.output[0].features_additional;
                this.facilitiyList = response.data.output[0].facilities;
                this.add_facilitiyList = response.data.output[0].facility_additional;
                this.otherDocList = response.data.output[0].doc_other_photos;
                this.mediaLinksList = response.data.output[0].media_links;                
                console.log(this.mode_of_payment);
                setTimeout(function(){
                    
                    window.$('.property-slider-f-slider').owlCarousel({
                        loop:false,
                        margin:0,
                        nav:true,
                        dots:false,
                        navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
                        responsive:{
                            0:{
                                items:1,
                            },
                            600:{
                                items:1
                            },
                            1000:{
                                items:1
                            }
                        }
                    })
                    
                },1000);
                this.mode_of_payment = JSON.parse(response.data.output[0].mode_of_payment);

            } catch (error) {
                console.error('Error fetching options:', error);
            }
        },
        getLabelByValue(value) {
            const item = this.listingType.find(option => option.value === value);
            return item ? item.label : 'Not found';
        },
        getPropertyType(value) {
            const item = this.propertyType.find(option => option.id === value);
            return item ? item.name : 'Not found';
        },
        getRentalPeriod(value) {
            const item = this.rentalPriodLIst.find(option => option.value === value);
            return item ? item.label : 'Not found';
        },
        maskedNumber(value) {
            if(value){
                const start = value.slice(0, 6); // +6016
                const end = value.slice(-3); // 108
                return `${start}****${end}`; // Combine parts with masking
            }
        },
        
     
    }
  }
  </script>
  
  <style scoped>
  .opacity-0{
    opacity:0
  }
  /* Add styles specific to the About Us page here */
  </style>
  