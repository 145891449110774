<template>
  <section class="agent-details-se">
    <div class="container">
      <div class="row">
        <div class="col-md-9">
          <div class="agent-details-box">
            <div class="agent-photo-box">
              <div class="agent-photo">
                <img :src="getUserProfileImage(agent?.profile_photo_name)" />
                <div class="logo">
                  <img src="frontend/images/logo.png">
                </div>
              </div>
              <div class="online-status">
                <span class="badge bg-success"></span>
              </div>
            </div>
            <div class="agent-details">
              <h4>{{ agent?.name }}</h4>
              <p><span>@{{ agent?.name }}</span></p>
              <ul class="icon-list">
                <li><img src="frontend/images/star.svg" alt=""> {{ agent?.user_service_details?.total_rating_points }} Ratings</li>
                <li><img src="frontend/images/thumbs-up-star.svg" alt=""> {{ agent?.user_service_details?.total_reviews }} reviews</li>
                <li><img src="frontend/images/handshakes.svg" alt=""> {{ agent?.user_service_details?.total_digital_handshakes }} Digital Handshakes</li>
                <li><img src="frontend/images/eye.svg" alt=""> {{ agent?.user_service_details?.total_views }} Views</li>
              </ul>
              <ul class="text-list">
                <li>CEA reg. no:<b>{{ agent?.user_service_details?.agency_registration_no }}</b></li>
                <li>Company Name:<b>{{ agent?.user_service_details?.company_name }}</b></li>
                <li>Agency Licence:<b>{{ agent?.user_service_details?.agency_license }}</b></li>
                <li>Position:<b>{{ agent?.user_service_details?.position_held }}</b></li>
                <li>Member Type:<b>{{ agent?.user_service_details?.agency_registration_no }}</b></li>
                <li>Member Since:<b>{{ yearsAgo(agent?.user_service_details?.personal_working_since) }}</b></li>
              </ul>
            </div>
          </div>
          <div class="agent-info-box profile-rating">
            <div class="comman-title">
              <h4>Profile Rating</h4>
            </div>
            <div class="agent-details">
              <ul class="text-list">
                <li>No. Of Active Listings<b>{{ agent?.user_service_details?.no_of_active_listings }}</b></li>
                <li>No. Of Enquiries<b>{{ agent?.user_service_details?.no_of_enquiries }}</b></li>
                <li>No. Of Assignments<b>{{ agent?.user_service_details?.no_of_assignments }}</b></li>
                <li>No. Of Service Requests<b>{{ agent?.user_service_details?.no_of_service_requests }}</b></li>
                <li>No. Of Hybrid-Listing<b>{{ agent?.user_service_details?.no_of_hybrid_listings }}</b></li>
                <li>Comments <b>{{ agent?.user_service_details?.comments }}</b></li>
                <li>Average Property Listing Score<b>{{ agent?.user_service_details?.average_property_listing_score }}</b></li>
              </ul>
            </div>
          </div>
          <div class="agent-info-box">
            <div class="comman-title">
              <h4>About Me</h4>
            </div>
            <p class="mb-0">{{ agent?.about_you }}</p>
          </div>
          <div class="agent-info-box">
            <div class="comman-title">
              <h4>My Services</h4>
            </div>
            <p class="mb-0">I am an Agent</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="agent-btn-box">
            <a href="#" class="comman-btn-5 w-100 mb-3"><img src="frontend/images/star.svg" alt=""> Save</a>
            <a href="#" class="comman-btn-5 w-100 mb-3"><img src="frontend/images/handshakes.svg" alt=""> Request My Service</a>
            <a href="#" class="comman-btn-2 w-100"><img src="frontend/images/email-ico.svg" alt=""> Contact</a>
          </div>
          <div class="agent-info-box">
            <div class="comman-title">
              <h4>Availability</h4>
            </div>
            <p>{{ agent?.user_service_details?.availability_days }} {{ agent?.user_service_details?.working_hours }}</p>
            <div class="comman-title">
              <h4>Reach Me</h4>
            </div>
            <p>{{ agent ? formatPhoneNumber(agent?.contact_phone) : '' }}</p>
            <div class="comman-title">
              <h4>My Socials</h4>
            </div>
            <div class="social">
              <a href="{{ agent?.twitter_url }}" v-if="agent?.twitter_url">
                <img src="frontend/images/tweet-ico.svg">
              </a>
              <a href="{{ agent?.facebook_url }}" v-if="agent?.facebook_url">
                <img src="frontend/images/fb-ico.svg">
              </a>
              <a href="{{ agent?.linkedin_url }}" v-if="agent?.linkedin_url">
                <img src="frontend/images/linkedin-ico.svg">
              </a>
              <a href="{{ agent?.youtube_url }}" v-if="agent?.youtube_url">
                <img src="frontend/images/youtube-ico.svg">
              </a>
              <a href="{{ agent?.tiktok_url }}" v-if="agent?.tiktok_url">
                <img src="frontend/images/tiktok-icon.svg">
              </a>
              <a href="{{ agent?.instagram_url }}" v-if="agent?.instagram_url">
                <img src="frontend/images/instagram-ico.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="custom-listing">
    <div class="container">
      <div class="filter-bar-se">
        <div class="comman-title mb-0">
          <h3>{{ agent?.name }}’s Listings</h3>
        </div>
        <div class="filter-right-bar">
          <div class="dropdown">
            <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
              <img src="frontend/images/filter-ico.svg"> Sort By
            </a>
            <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
              <li><a class="dropdown-item" href="#" @click.prevent="sortProperties('Newest')">Newest</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row row-cols-lg-4 row-cols-sm-2">
        <div v-if="properties && properties.length === 0">
          No records found.
        </div>
        <div v-else class="col" v-for="property in properties" :key="property.id">
          <div class="project-bx">
            <div class="heart-pro">
              <a href=""><i class="fa fa-heart-o"></i></a>
            </div>
            <div class="property-carousel owl-carousel owl-theme">
              <img
                v-for="(image, index) in property.actual_unit_photo"
                :key="index"
                :src="getPropertyImage(image.photo_name)"
                :alt="property.property_name"
              />
            </div>
            <div class="project-info">
              <div class="d-md-flex mb-2 align-items-center">
                <h3><a :href="`/property/${property.id}`">{{ property.property_name }}</a></h3>
                <div class="price-pro ms-md-auto">
                  <span><small>s$</small> {{ property.asking_price_sale_rent }}</span>
                </div>
              </div>
              <p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg" alt="Location Icon"></i>{{ property.street }}, {{
                  property.district
                }}</p>
              <div class="pro-specify">
                <span><i><img src="frontend/images/spec-ico1.svg" alt="Rooms"></i>{{ property.no_of_rooms }}</span>
                <span><i><img src="frontend/images/spec-ico2.svg" alt="Baths"></i>{{ property.no_of_baths }}</span>
                <span><i><img src="frontend/images/spec-ico3.svg" alt="Parking"></i>{{ property.no_car_park_lots }}</span>
                <span><i><img src="frontend/images/spec-ico4.svg" alt="Area"></i>{{ property.floor_area }} {{ property.floor_area_unit }}</span>
              </div>
              <div class="pro-tag">
                <a v-for="tag in property.pro_tag" :key="tag" href="#">{{ tag }}</a>
              </div>
              <ul>
                <li v-for="spec in property.specifications" :key="spec.id">
                  <figure>
                    <img :src="spec.icon" alt="Specification Icon">
                  </figure>
                  <small>{{ spec.description }}</small>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.profile-rating ul.text-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.profile-rating ul.text-list li {
  color: var(--grey-4B4A4A);
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 40%;
  font-size: 14px;
}
</style>

<script>
import { mapState } from 'vuex';
import { CONSTANT } from "@/constants/constants";
import { formatPhoneNumber, getPropertyImage, getUserProfileImage, yearsAgo } from "@/utils/helpers";

export default {
  data() {
    return {
      agent: null,
      sort_by: 'Newest',
      properties: [],
    };
  },
  computed: {
    CONSTANT() {
      return CONSTANT
    },
    ...mapState(['user']),
  },
  async created() {
    await this.fetchAgentDetails();
  },
  methods: {
    getPropertyImage,
    getUserProfileImage,
    yearsAgo,
    formatPhoneNumber,
    async fetchAgentDetails() {
      const agentId = this.$route.params.id;
      try {
        const response = await this.$axios.post('/api/agent-detail', {
          user_id: this.user.id,
          agent_id: agentId,
          sort_by: this.sort_by,
        });

        this.agent = response.data.output;
        this.properties = this.agent.property_details;
        this.$nextTick(() => {
          this.initializeOwlCarousel();
        });
      } catch (error) {
        console.error('Error fetching agent details:', error);
      }
    },
    sortProperties(sortBy) {
      this.sort_by = sortBy;
    },
    initializeOwlCarousel() {
      window.$('.property-carousel').owlCarousel({
        loop: false,
        margin: 0,
        nav: true,
        dots: true,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
          0: {
            items: 1,
            margin: 0
          },
          600: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
    },
  },
};
</script>
