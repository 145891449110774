import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import AboutUs from '../views/AboutUs.vue'
import HomePage from '../views/HomePage.vue'
import SignIn from '../views/SignIn.vue'
import ProfilePage from '../views/ProfilePage.vue'
import store from '../store';
import SignUp from '@/views/SignUp.vue'
import SignUpSocial from "@/views/SignUpSocial.vue";
import SignUpBusiness from '@/views/SignUpBusiness.vue'
import SignUpCorporate from '@/views/SignUpCorporate.vue'
import VerifyNumber from '@/views/VerifyNumber.vue'
import PostProperyListing from '@/views/listing/PostProperyListing.vue'
import PostProperyListingStep2 from '@/views/listing/PostProperyListingStep2.vue'
import PostProperyListingStep3 from '@/views/listing/PostProperyListingStep3.vue'
import PostProperyListingStep4 from '@/views/listing/PostProperyListingStep4.vue'
import PostProperyListingStep5 from '@/views/listing/PostProperyListingStep5.vue'
import MyProperty from '@/views/listing/MyProperty.vue'
import PropertyDetail from '@/views/listing/PropertyDetail.vue'
import ExploreMore from '@/views/property/ExploreMore.vue'
import PostProjectListing from '@/views/project/PostProjectListing.vue'
import PostProjectListingStep2 from '@/views/project/PostProjectListingStep2.vue'
import PostProjectListingStep4 from '@/views/project/PostProjectListingStep4.vue'
import PostProjectListingStep3 from '@/views/project/PostProjectListingStep3.vue'
import MyProjects from '@/views/project/MyProjects.vue'
import ProjectDetail from '@/views/project/ProjectDetail.vue'

import PackagePage from "@/views/PackagePage";
import PaymentPage from "@/views/PaymentPage";
import PaymentSuccessPage from "@/views/PaymentSuccessPage";
import TopUpPage from "@/views/TopUpPage.vue";
import toastr from 'toastr';
import AgentPage from "@/views/AgentPage.vue";
import AgentDetailPage from "@/views/AgentDetailPage.vue";
import Widget1Page from '@/views/widget/Widget1Page.vue'
import Widget2Page from '@/views/widget/Widget2Page.vue'
import Widget3Page from '@/views/widget/Widget3Page.vue'
import Widget0Page from '@/views/widget/Widget0Page.vue'

const routes = [
  {
    mode: 'history',
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: HomePage
      },
      {
        path: 'ExploreMore',
        name: 'ExploreMore',
        component: ExploreMore
       
      },
      {
        path: 'sign-up',
        name: 'SignUp',
        component: SignUp,
        meta: { guest: true }
      },
      {
        path: 'sign-up-social',
        name: 'SignUpSocial',
        component: SignUpSocial,
        meta: { guest: true }
      },
      {
        path: 'sign-up-business',
        name: 'SignUpBusiness',
        component: SignUpBusiness,
        meta: { guest: true }
      },
      {
        path: 'sign-up-corporate',
        name: 'SignUpCorporate',
        component: SignUpCorporate,
        meta: { guest: true }
      },
      {
        path: 'sign-in',
        name: 'SignIn',
        component: SignIn,
        meta: { guest: true }
      },
      {
        path: 'verify-number',
        name: 'verifyNumber',
        component: VerifyNumber,
        meta: { requiresAuth: true },
        beforeEnter: (to, from, next) => {
          if (store.state.user.is_mobile_verified) {
            toastr.success('Your number is already verified!');
            next({ name: 'ProfilePage' });
            return true;
          }
          next();
        }
      },
      {
        path: 'post-property-listing/:id?',
        name: 'postPropertyListing',
        component: PostProperyListing,
        meta: { requiresAuth: true }
      },
      {
        path: '/post-property-listing-2/:id',
        name: 'postPropertyListingStep2',
        component: PostProperyListingStep2,
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: 'post-property-listing-3/:id',
        name: 'postPropertyListingStep3',
        component: PostProperyListingStep3,
        meta: { requiresAuth: true }
      },
      {
        path: 'post-property-listing-4/:id',
        name: 'postPropertyListingStep4',
        component: PostProperyListingStep4,
        meta: { requiresAuth: true }
      },
      {
        path: 'post-property-listing-5/:id',
        name: 'postPropertyListingStep5',
        component: PostProperyListingStep5,
        meta: { requiresAuth: true }
      },
      {
        path: 'property-detail/:id',
        name: 'propertyDetail',
        component: PropertyDetail
      },
      {
        path: 'widget-property-post/:id/:id_type',
        name: 'Widget0Page',
        component: Widget0Page,
        meta: { hideHeaderFooter: true }
      },
      {
        path: 'widget-property-post-1/:id/:id_type',
        name: 'Widget1Page',
        component: Widget1Page,
        meta: { hideHeaderFooter: true }
      },
      {
        path: 'widget-property-post-2/:id/:id_type/:post_listing_id',
        name: 'Widget2Page',
        component: Widget2Page,
        meta: { hideHeaderFooter: true }
      },
      {
        path: 'widget-property-post-3/:id/:id_type/:post_listing_id',
        name: 'Widget3Page',
        component: Widget3Page,
        meta: { hideHeaderFooter: true }
      },
      {
        path: 'project-detail/:id',
        name: 'projectDetail',
        component: ProjectDetail
           },
      {
        path: 'my-property',
        name: 'MyProperty',
        component: MyProperty,
        meta: { requiresAuth: true }
      },
      {
        path: 'my-project',
        name: 'MyProject',
        component: MyProjects,
        meta: { requiresAuth: true }
      },
      {
        path: 'post-project-listing/:id?',
        name: 'postProjectListing',
        component: PostProjectListing,
        meta: { requiresAuth: true }
      },
      {
        path: '/post-project-listing-2/:id',
        name: 'postProjectListingStep2',
        component: PostProjectListingStep2,
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: '/post-project-listing-3/:id',
        name: 'postProjectListingStep3',
        component: PostProjectListingStep3,
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: '/post-project-listing-4/:id',
        name: 'postProjectListingStep4',
        component: PostProjectListingStep4,
        props: true,
        meta: { requiresAuth: true }
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: AboutUs
      },
      {
        path: 'profile',
        name: 'ProfilePage',
        component: ProfilePage,
        meta: { requiresAuth: true }
      },
      {
        path: 'packages',
        name: 'Packages',
        component: PackagePage,
        meta: { requiresAuth: true }
      },
      {
        path: 'payments',
        name: 'Payments',
        component: PaymentPage,
        meta: { requiresAuth: true },
        props: route => ({ packageId: route.params.packageId })
      },
      {
        path: 'payments/success',
        name: 'PaymentSuccess',
        component: PaymentSuccessPage,
        meta: { requiresAuth: true }
      },
      {
        path: 'top-up',
        name: 'TopUp',
        component: TopUpPage,
        meta: { requiresAuth: true }
      },
      {
        path: 'agents',
        name: 'Agents',
        component: AgentPage
      },
      {
        path: 'agents/:id',
        name: 'AgentDetail',
        component: AgentDetailPage,
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(), // Base URL is automatically handled by Vite
  routes
})

// Route guard to check if the user is authenticated before accessing protected routes




router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated;
  const isUser = store.state.user;
  console.log('is logoed in = '+isAuthenticated);
  console.log('is User = '+isUser);
  
  if (to.matched.some(record => record.meta.guest) && isAuthenticated) {
    next('/profile'); // Redirect to profile or another appropriate page
  } else if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/sign-in'); // Redirect to sign-in if not authenticated
  } else {
    next(); // Allow navigation
  }

});


export default router