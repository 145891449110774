<template>
	<div class="page-wrap">
		<section class="mrp-search-box-se">
			<div class="container">				
				<div class="row">
					<div class="col-md-8 offset-md-2">
						<ul class="nav nav-tabs" id="myTab" role="tablist">
							<li class="nav-item" role="presentation">
								<button class="nav-link" :class="{ active: activeTab === 'Buy' }" id="Buy-tab" data-bs-toggle="tab" data-bs-target="#Buy" type="button" role="tab" aria-controls="Buy" aria-selected="true" @click="setActiveTab('Buy')">Buy</button>
							</li>
							<li class="nav-item" role="presentation">
								<button class="nav-link" :class="{ active: activeTab === 'Rent' }" id="Rent-tab" data-bs-toggle="tab" data-bs-target="#Rent" type="button" role="tab" aria-controls="Rent" aria-selected="false" @click="setActiveTab('Rent')">Rent</button>
							</li> 
						</ul>
						<div class="tab-content" id="myTabContent">
							<div class="tab-pane fade show active" id="Buy" role="tabpanel" aria-labelledby="Buy-tab">
								<ExploreMoreSearch @submit="submitSearch" type="Buy"></ExploreMoreSearch>
							</div>
							<div class="tab-pane fade" id="Rent" role="tabpanel" aria-labelledby="Rent-tab">
								<ExploreMoreSearch @submit="submitSearch" type="Rent"></ExploreMoreSearch>
							</div>
						</div> 
					</div>
				</div>
			</div>
		</section> 

		<section class="mrp-search-se">
			<div class="container">
				<div class="filter-bar-se mb-0">
					<div class="comman-title mb-0">
						<h3 class="mb-2">Most Recent Posting</h3>
						<span>Showing {{ totalRecords }} properties</span>
					</div>
					<div class="filter-right-bar"> 
						<!-- <div class="dropdown">
							<a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
								<img src="frontend/images/filter-ico.svg"> Filter By
							</a>
							<ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
								<li><a class="dropdown-item" href="#">Recommended </a></li>
								<li><a class="dropdown-item" href="#">111</a></li>								
							</ul>
						</div> -->
						<div class="dropdown">
							<a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
								<img src="frontend/images/Sort-By.svg"> Sort By
							</a>
							<ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
								<li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Newest' }" @click.prevent="sortProperties('Newest')">Newest</a></li>
								<li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Lowest Price' }" @click.prevent="sortProperties('Lowest Price')">Lowest Price</a></li>
								<li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Highest Price' }" @click.prevent="sortProperties('Highest Price')">Highest Price</a></li>
								<li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Lowest Floor Area' }" @click.prevent="sortProperties('Lowest Floor Area')">Lowest Floor Area</a></li>
								<li><a class="dropdown-item" href="#" :class="{ 'selected': filters.sort_by_field === 'Highest Floor Area' }" @click.prevent="sortProperties('Highest Floor Area')">Highest Floor Area</a></li>
							</ul>
						</div>
					</div>
				</div>
 
				<div class="property-listing">
					<div v-if="properties.length === 0">
						No records found.
					</div>
					<div v-else class="property-listing-box" v-for="property in properties" :key="property.id">
						<div class="property-listing-img">
							<div class="property-carousel owl-carousel owl-theme">
								<img
									v-for="(image, index) in property.actual_unit_photo"
									:key="index"
									:src="getPropertyImage(image.photo_name)"
									:alt="property.property_name"
								/>
							</div>
							<div class="mark-logo">
								<img src="frontend/images/logo.png" alt="logo">
							</div>
						</div>
						<div class="property-listing-contant">
							<div class="project-info">								
								<h3><a @click="redirectToPropertyDetails">{{ property.property_name }}</a></h3>
								<p class="loct-info">
									<img src="frontend/images/map-gol-ico.svg">
									{{ property.street }}, {{  property.district }}
								</p>
								<div class="price-pro ms-md-auto">
									<span>S$ {{ property.asking_price_sale_rent }} <small>($ {{computedSQF(property)}}/SQF)</small></span>
								</div>
								<div class="pro-specify">
									<span><i><img src="frontend/images/spec-ico1.svg"></i>{{ property.no_of_rooms }}</span>
									<span><i><img src="frontend/images/spec-ico2.svg"></i>{{ property.no_of_baths }}</span>
									<span><i><img src="frontend/images/spec-ico3.svg"></i>{{ property.no_car_park_lots }}</span>
									<span><i><img src="frontend/images/spec-ico4.svg"></i>{{ property.floor_area }} {{ property.land_area_unit }}</span>
								</div>
								<div class="pro-tag">
										<a href="#">{{ property.property_type_details.parent_id_top_level_1_code }}</a>
										<a href="#">{{ property.property_type_details.code }}</a>
										<a href="#">{{ property.land_title_type }}</a>
									</div>
									<ul>
									<li v-if="property.is_in_wishlist">
										<figure>
											<img src="frontend/images/heart-ico.svg">
										</figure>
										<small>favorite</small>
									</li>
									<li v-if="property.is_direct_owner">
										<figure>
											<img src="frontend/images/specify-ico1.png">
										</figure>
										<small>Direct<br>Owner</small>
									</li>
									<li v-if="property.is_sing_pass || property.is_corp_pass ">
										<figure>
											<img src="frontend/images/specify-ico2.png">
										</figure>
										<small>SingPass<br>Verified </small>
									</li>
									<li v-if="property.is_document_approved">
										<figure>
											<img src="frontend/images/specify-ico3.png">
										</figure>
										<small>Documents<br>Verified</small>
									</li>
									<li v-if="property.is_FSM_linked">
										<figure>
											<img src="frontend/images/specify-ico4.png">
										</figure>
										<small>Fengshui<br>Map</small>
									</li>
									<li v-if="property.is_assigned_to_the_agent">
										<figure>
											<img src="frontend/images/specify-ico5.png">
										</figure>
										<small>Hybrid-Listing<br>Owners </small>
									</li>
									<li v-if="property.is_below_mean_value">
										<figure>
											<img src="frontend/images/Down-arrow.svg">
										</figure>
										<small>BMV</small>
									</li>
								</ul>
							</div>
						</div>
						<div class="property-listing-profile">
							<div class="property-l-profile-box">
								<div class="profile-box">
									<img :src="getUserProfileImage(property.user_id_details?.profile_photo_name)" />
									<div>
										<span>Posted By</span>
										<h5>{{ property.user_id_details?.name }} <img src="frontend/images/varify-user.svg" alt=""></h5>
									</div>
								</div>
								<div class="agency-reg-no"> Agency Reg No:<span> {{property.user_id_details?.user_service_details?.agency_registration_no }}</span></div>
								<div class="property-l-profile-btn">
									<a href="#" class="comman-btn-2 whatsapp-btn w-100"><b class="mb-0">WhatsApp</b></a>
									<a href="#" class="comman-btn-6 w-100"><b>Email</b></a>
								</div>
							</div>
							<div class="property-l-profile-box">
								<p>Partner With Me to Market Your Property</p>
								<a href="#" class="comman-btn-2">
									<span>
										<b>Create A Hybrid Listing</b><br>
										Own  Your Story, Amplity its Reach!
									</span>
								</a>
							</div>
						</div>
					</div>			
					<div class="pagination-se">
						<nav aria-label="...">
							<ul class="pagination justify-content-center">
								<li
									class="page-item"
									:class="{ disabled: filters.page === 1 }"
									@click="filters.page > 1 && changePage(filters.page - 1)"
									v-if="filters.page > 1"
								>
									<a class="page-link"><img src="frontend/images/left-arrow-icon.svg" alt="Previous"></a>
								</li>
								<li v-for="pageNumber in paginatedPages(filters.page, totalPages)"
									:key="pageNumber"
									class="page-item"
									:class="{ active: pageNumber === filters.page }"
								>
									<a
										class="page-link"
										@click.prevent="pageNumber !== filters.page && changePage(pageNumber)"
									>
										{{ pageNumber }}
									</a>
								</li>
								<li class="page-item"
									:class="{ disabled: filters.page === totalPages }"
									@click="filters.page < totalPages && changePage(filters.page + 1)"
									v-if="filters.page < totalPages"
								>
									<a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
								</li>
							</ul>
						</nav>
					</div>			
				</div>
			</div>
		</section> 

		<section class="offer-pro-sec">
			<div class="container">
				<div class="comman-title d-md-flex align-items-center justify-content-between">
					<div class="offer-heading">						
						<span>Browse more offers</span>
						<h3>recently browsed properties</h3>
					</div>
					<div class="readmore ms-md-auto">
						<a href="">Explore more <i><img src="frontend/images/rgt-arrow.svg"></i></a>
					</div>
				</div>
				<div class="property-carousel owl-carousel owl-theme">
					<div class="item">
						<div class="project-bx">
							<div class="heart-pro">
								<a href=""><i class="fa fa-heart-o"></i></a>
							</div>
							<picture>
								<img src="frontend/images/project-img1.jpg">
							</picture>
							<div class="project-info">
								<div class="d-md-flex mb-2 align-items-center">
									<h3><a href="">Property name</a></h3>
									<div class="price-pro ms-md-auto">
										<span><small>s$</small> 3000-40,000</span>
									</div>
								</div>
								<p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
								<div class="pro-specify">
									<span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
									<span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
									<span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
									<span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
								</div>
								<div class="pro-tag">
									<a href="#">De</a>
									<a href="#">Condo</a>
									<a href="#">Freehold</a>
								</div>
								<ul>
									<li>
										<figure>
											<img src="frontend/images/specify-ico1.png">
										</figure>
										<small>Direct<br>Owner</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico2.png">
										</figure>
										<small>SingPass<br>Verified </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico3.png">
										</figure>
										<small>Documents<br>Verified</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico4.png">
										</figure>
										<small>Fengshui<br>Map</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico5.png">
										</figure>
										<small>Hybrid-Listing<br>Owners </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/Down-arrow.svg">
										</figure>
										<small>BMV</small>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="project-bx">
							<div class="heart-pro">
								<a href=""><i class="fa fa-heart-o"></i></a>
							</div>
							<picture>
								<img src="frontend/images/project-img2.jpg">
							</picture>
							<div class="project-info">
								<div class="d-md-flex mb-2 align-items-center">
									<h3><a href="">Property name</a></h3>
									<div class="price-pro ms-md-auto">
										<span><small>s$</small> 3000-40,000</span>
									</div>
								</div>
								<p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
									<div class="pro-specify">
										<span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
										<span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
										<span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
										<span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
									</div>
									<div class="pro-tag">
										<a href="#">De</a>
										<a href="#">Condo</a>
										<a href="#">Freehold</a>
									</div>
									<ul>
									<li>
										<figure>
											<img src="frontend/images/specify-ico1.png">
										</figure>
										<small>Direct<br>Owner</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico2.png">
										</figure>
										<small>SingPass<br>Verified </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico3.png">
										</figure>
										<small>Documents<br>Verified</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico4.png">
										</figure>
										<small>Fengshui<br>Map</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico5.png">
										</figure>
										<small>Hybrid-Listing<br>Owners </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/Down-arrow.svg">
										</figure>
										<small>BMV</small>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="project-bx">
							<div class="heart-pro">
								<a href=""><i class="fa fa-heart-o"></i></a>
							</div>
							<picture>
								<img src="frontend/images/project-img3.jpg">
							</picture>
							<div class="project-info">
								<div class="d-md-flex mb-2 align-items-center">
									<h3><a href="">Property name</a></h3>
									<div class="price-pro ms-md-auto">
										<span><small>s$</small> 3000-40,000</span>
									</div>
								</div>
								<p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
									<div class="pro-specify">
										<span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
										<span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
										<span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
										<span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
									</div>
									<div class="pro-tag">
										<a href="#">De</a>
										<a href="#">Condo</a>
										<a href="#">Freehold</a>
									</div>
									<ul>
									<li>
										<figure>
											<img src="frontend/images/specify-ico1.png">
										</figure>
										<small>Direct<br>Owner</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico2.png">
										</figure>
										<small>SingPass<br>Verified </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico3.png">
										</figure>
										<small>Documents<br>Verified</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico4.png">
										</figure>
										<small>Fengshui<br>Map</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico5.png">
										</figure>
										<small>Hybrid-Listing<br>Owners </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/Down-arrow.svg">
										</figure>
										<small>BMV</small>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="project-bx">
							<div class="heart-pro">
								<a href=""><i class="fa fa-heart-o"></i></a>
							</div>
							<picture>
								<img src="frontend/images/project-img3.jpg">
							</picture>
							<div class="project-info">
								<div class="d-md-flex mb-2 align-items-center">
									<h3><a href="">Property name</a></h3>
									<div class="price-pro ms-md-auto">
										<span><small>s$</small> 3000-40,000</span>
									</div>
								</div>
								<p class="loct-info"><i><img src="frontend/images/map-gol-ico.svg"></i>Sentosa Island, Singapore</p>
									<div class="pro-specify">
										<span><i><img src="frontend/images/spec-ico1.svg"></i>2</span>
										<span><i><img src="frontend/images/spec-ico2.svg"></i>2-4</span>
										<span><i><img src="frontend/images/spec-ico3.svg"></i>2</span>
										<span><i><img src="frontend/images/spec-ico4.svg"></i>1,005 SQF</span>
									</div>
									<div class="pro-tag">
										<a href="#">De</a>
										<a href="#">Condo</a>
										<a href="#">Freehold</a>
									</div>
									<ul>
									<li>
										<figure>
											<img src="frontend/images/specify-ico1.png">
										</figure>
										<small>Direct<br>Owner</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico2.png">
										</figure>
										<small>SingPass<br>Verified </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico3.png">
										</figure>
										<small>Documents<br>Verified</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico4.png">
										</figure>
										<small>Fengshui<br>Map</small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/specify-ico5.png">
										</figure>
										<small>Hybrid-Listing<br>Owners </small>
									</li>
									<li>
										<figure>
											<img src="frontend/images/Down-arrow.svg">
										</figure>
										<small>BMV</small>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="testimonial-sec">
			<div class="container">
				<div class="comman-title text-center">
					<span>Testimonials</span>
					<h3>what our customer say about us</h3>
				</div>
				<div class="testimonail-carousel owl-carousel owl-theme">
					<div class="item">
						<div class="testimoanil-bx">
							<div class="client-header">
								<figure>
									<img src="frontend/images/agent1.png">
								</figure>
								<div class="client-nm">
									<h4>Jane Cooper</h4>
									<div class="rating-show">
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
									</div>
								</div>
							</div>
							<div class="testimoanil-content">
								<p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="testimoanil-bx">
							<div class="client-header">
								<figure>
									<img src="frontend/images/agent2.jpg">
								</figure>
								<div class="client-nm">
									<h4>Ralph Edwards</h4>
									<div class="rating-show">
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
									</div>
								</div>
							</div>
							<div class="testimoanil-content">
								<p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="testimoanil-bx">
							<div class="client-header">
								<figure>
									<img src="frontend/images/agent3.png">
								</figure>
								<div class="client-nm">
									<h4>Brooklyn Simmons</h4>
									<div class="rating-show">
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
									</div>
								</div>
							</div>
							<div class="testimoanil-content">
								<p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
							</div>
						</div>
					</div>				    
					<div class="item">
						<div class="testimoanil-bx">
							<div class="client-header">
								<figure>
									<img src="frontend/images/agent1.png">
								</figure>
								<div class="client-nm">
								<h4>Jane Cooper</h4>
								<div class="rating-show">
								<i class="fa fa-star"></i>
								<i class="fa fa-star"></i>
								<i class="fa fa-star"></i>
								<i class="fa fa-star"></i>
								</div>
								</div>
								</div>
								<div class="testimoanil-content">
								<p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="testimoanil-bx">
							<div class="client-header">
								<figure>
									<img src="frontend/images/agent2.jpg">
								</figure>
							<div class="client-nm">
								<h4>Ralph Edwards</h4>
								<div class="rating-show">
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
								</div>
							</div>
							</div>
							<div class="testimoanil-content">
								<p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
							</div>
						</div>
					</div>
					<div class="item">
						<div class="testimoanil-bx">
							<div class="client-header">
								<figure>
									<img src="frontend/images/agent3.png">
								</figure>
								<div class="client-nm">
									<h4>Brooklyn Simmons</h4>
									<div class="rating-show">
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
										<i class="fa fa-star"></i>
									</div>
								</div>
							</div>
							<div class="testimoanil-content">
								<p>"We love our new Ang Mo Kio home! It's spacious with stunning skyline views and convenient MRT access. The neighborhood is vibrant, perfect for families. Property management is attentive and responsive. Highly recommended!"</p>
							</div>
						</div>
					</div>				    
				</div>
			</div>
		</section> 
	</div>	
</template>

<script>
import ExploreMoreSearch from './ExploreMoreSearch.vue';
import {getUserProfileImage, paginatedPages, getPropertyImage } from '@/utils/helpers';

export default {
    name: 'ExploreMore',

	components: {ExploreMoreSearch},

	data() {
		return {
			activeTab: 'Buy',
			properties: [],
			totalPages: 0,
			totalRecords: 0,
			filters: {
				page: 1,
				sort_by_field: 'Newest',
				keywords: ""
			}
		}
	},

	async created() {
		await this.fetchProperties();
	},

	mounted() {
		window.$(document).ready(function() {
			window.$('.property-image-carousel').owlCarousel({
				loop:false,
				margin:20,
				nav:true,
				dots:false,
				navText: [ '<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>' ],
				responsive:{
					0:{
						items:1,
						margin:10
					},
					600:{
						items:2
					},
					1000:{
						items:3
					}
				}
			})
			window.$('.testimonail-carousel').owlCarousel({
				loop:false,
				margin:25,
				nav:false,
				dots:true,
				responsive:{
					0:{
						items:1
					},
					600:{
						items:2
					},
					1000:{
						items:3
					}
				}
			})
		});

		this.fetchProperties();
	},

	methods: {
		getUserProfileImage,
		getPropertyImage,
		paginatedPages,

		setActiveTab(tab) {
			this.activeTab = tab;
		},

		computedSQF(property) {
			return property.asking_price_sale_rent > 0 ? Math.ceil(property.asking_price_sale_rent/property.floor_area) : 0;
		},

		async fetchProperties() {
			try {
				const propertiesResponse = await this.$axios.post('api/property-search-browse', { 
					"user_id":50, 
					"is_for_sale" : 1, 
					"is_for_rent" : 1, 
					"listing_type": 2, 
					"property_type": "7,3,5", 
					"keywords": this.filters.keywords, 
					"gender": "Male", 
					"date_of_birth": "20-Sep-2001", 
					"is_featured": 1, 
					"is_FSM_linked": 0, 
					"is_widget_property": 0, 
					"is_curated_property": 0, 
					"is_popular": 0,
					"is_hot_location":0, 
					"asking_price_sale_rent_min":0,
					"asking_price_sale_rent_max":99999999,
					"floor_area_min_sqf":0,
					"floor_area_max_sqf":99999999, 
					"land_area_min_sqf":0, 
					"land_area_max_sqf":99999999, 
					"no_of_rooms":"2,3,4",
					"land_tenure":"FreeHold,LeaseHold 999 Years,LeaseHold 99 Years,LeaseHold 30-60 Years,Others", 
					"land_title_type":"HDB,Strata,Landed", 
					"furnishing_fitting_ids":"1,2,3,4,5", 
					"floor_level_id":"2,3,4,5", 
					"sort_by_field": this.filters.sort_by_field,
					"page_number": this.filters.page 
				});

				this.totalPages = propertiesResponse.data.output.no_of_total_pages ?? 0;
				this.totalRecords = propertiesResponse.data.output.no_of_total_records ?? 0;
				this.properties = propertiesResponse.data.output.property_list ?? [];

				this.$nextTick(() => {
					this.initializeOwlCarousel();
				});
			} catch (error) {
				console.error('Error fetching properties:', error);
			}
		},

		sortProperties(sortBy) {
			this.filters.sort_by_field = sortBy;
			this.filters.page = 1;
			this.fetchProperties();
		},

		changePage(page) {
			if (page > 0 && page <= this.totalPages) {
				this.filters.page = page;
				this.fetchProperties();
			}
		},
		
		submitSearch(data) {
			this.filters.keywords = data.searchQuery;
			this.filters.page = 1;
			this.fetchProperties();
		},

		initializeOwlCarousel() {
			window.$('.property-carousel').owlCarousel({
				loop: false,
				margin: 0,
				nav: true,
				dots: true,
				navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
				responsive: {
					0: {
						items: 1,
						margin: 0
					},
					600: {
						items: 1
					},
					1000: {
						items: 1
					}
				}
			});
		},

		redirectToPropertyDetails() {
			window.location.href = 'https://pv-frontend.tess.gg/property-detail/28';
		}
	}
}
</script>

<style scoped>
.dropdown-item:hover, .dropdown-item.selected {
	background-color: #ffe6b9; 
}
</style>