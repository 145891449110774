<template>
    <div class="search-form">
        <div class="tab-title form-group">                      
            <div class="row align-items-center">
            <div class="col-md-6 col-lg-6">
                <p>Find Properties By Your Destiny Profile <i class="fa fa-question-circle"></i></p>
            </div>
            <div class="col-md-3 col-lg-3 col-6">                         
                <select class="form-control" v-model="formData.gender">
                    <option value="" disabled selected hidden>Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                </select>                         
            </div>
            <div class="col-md-3 col-lg-3 col-6">
                <input type="date" placeholder="Date Of Birth" class="form-control" v-model="formData.dateOfBirth">												                      
            </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row align-items-center">
            <div class="col-md-9 col-lg-9">
                <input type="text" class="form-control" name="emailid" v-model="formData.searchQuery" placeholder="Search By Property Name, District, HDB Estate, Area, MRT Or School" >
            </div>                        
            <div class="col-md-3 col-lg-3">
                <button type="submit" class="w-100 btn comman-btn-2" @click="submitForm">
                <i class="me-2"><img src="frontend/images/search-ico.svg"></i>Search
                </button>
            </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-4 col-6">                        
                <select class="form-control" title="Listing Type">
                    <option value="" disabled selected hidden>Listing Type</option>
                    <option v-for="(option, index) in mappedListingTypes" :key="index" :value="option">
                        {{ option }}
                    </option>
                </select>
            </div>
            <div class="col-md-4 col-6">                        
                <div :class="dropdownClass" >
                    <div class="dropdown">
                        <button class="form-control btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                            Select Property Type
                        </button>
                        <div class="dropdown-menu  p-4" aria-labelledby="dropdownMenuButton" @click.stop="">
                            <!-- Radio buttons for selecting the main category -->
                            <div class="form-check" @click.stop="">
                                <input class="form-check-input" type="radio" id="residential" value="residential" v-model="selectedType">
                                <label class="form-check-label" for="residential">Residential</label>
                            </div>
                            <div class="form-check" @click.stop="">
                                <input class="form-check-input" type="radio" id="commercial" value="commercial" v-model="selectedType">
                                <label class="form-check-label" for="commercial">Commercial & Industrial</label>
                            </div>
                            
                            <!-- Display categories based on the selected type -->
                            <div v-if="selectedType" class="mt-3">
                                <div v-for="(items, category) in categories[selectedType]" :key="category">
                                    <div class="form-check" @click.stop="">
                                        <input class="form-check-input" type="checkbox" :id="category" @change="selectAll(category, $event.target.checked)">
                                        <label class="form-check-label" :for="category">{{ category }}</label>
                                    </div>
                                    <div style="padding-left: 20px;">
                                    <div class="form-check" v-for="item in items" :key="item" @click.stop="">
                                        <input class="form-check-input" type="checkbox" :id="item" :name="category" @change="updateParentCheckbox(category)">
                                        <label class="form-check-label" :for="item">{{ item }}</label>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                       
            </div>
            <div class="col-md-4">
                <button type="submit" class="w-100 form-control">
                    More Filters <i class="ms-2"><img src="frontend/images/filter-ico.svg"></i>
                </button>
            </div>
        </div>          
    </div>
</template>

<script>
    export default {
        name: 'PropertySearch',

        props: {
            type: {
                type: String,
                Required: true
            }
        },

        data() {
            return {
                formData: {
                    gender: '',
                    dob: '',
                    searchQuery: '',
                    listingType: '',
                    propertyType: []
                },                             
                isDropdownOpen: false,
                selectedType: null,
                showCategories: [],
                categories: {
                    residential: {
                    'All HDB': ['HDB Flat', 'HDB Executive'],
                    'All Non-Landed (Private)': ['Apartment', 'Walk-Up Apartment', 'Condominium', 'Executive Condominium'],
                    'All Landed': ['Terrace / Link House', 'Town House', 'Cluster House', 'Conservative House', 'Semi-Detached/Zero-Lot', 'Detached/Bungalow', 'Good Class Bungalow (GCB)', 'Vacant Land']
                    },
                    commercial: {
                    'All Commercial': ['Retail', 'Office', 'Hotel / Hostel', 'Shopping Mall', 'Medical Suite', 'ShopHouse', 'HDB ShopHouse', 'Vacant Land'],
                    'All Industrial': ['Office', 'Warehouse (B1)', 'Factory (B2)', 'Vacant Land']
                    }
                }
            }
        },

        mounted() {
            document.addEventListener('click', this.handleOutsideClick);
        },
       
        computed: {
            mappedListingTypes() {
                // Map property types based on the parent prop
                return this.type === 'Buy'
                    ? ['Resale / Subsale (Pre-Owned)', 'Developer (New Launch / Off-The-Plan)', 'Developer (TOP / Completed)', 'Classified (Hybrid-Listing - FSBO / FRBO)']
                    : ['Rent (Entire Unit)', 'Rent (Rooms / Co-Living / Co-Working)', 'Classified (Hybrid-Listing - FSBO / FRBO)'];
            },

            dropdownClass() {
                // Add logic to adjust the class based on selected options
                if (this.selectedType) {
                    return 'extended-dropdown'; // Class to adjust dropdown size
                }
                return '';
            }
        },

        methods: {
            selectAll(category, isChecked) {
                const checkboxes = document.querySelectorAll(`input[name="${category}"]`);
                checkboxes.forEach(checkbox => {
                    checkbox.checked = isChecked;
                });
            },

            updateParentCheckbox(category) {
                const allChecked = document.querySelectorAll(`input[name="${category}"]:checked`).length;
                const allItems = document.querySelectorAll(`input[name="${category}"]`).length;
                const parentCheckbox = document.getElementById(category);
                parentCheckbox.checked = allChecked === allItems;
            },

            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },

            handleOutsideClick(e) {
                if (!this.$el.contains(e.target) && this.isDropdownOpen) {
                    this.isDropdownOpen = false;
                }
            },

            submitForm() {
                this.$router.push({ name: 'ExploreMore' });
            }
        }
    }
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  z-index: 999;
  background: white;
  border: 1px solid #ccc;
  max-height: 300px;
  overflow-y: auto;
}

.btn-dropdown {
    background-color: #f2f2f2; /* Light grey background */
    color: #333; /* Dark grey text color */
    border: none; /* No border */
    padding: 8px 16px; /* Top and bottom padding 8px, left and right 16px */
    font-size: 16px; /* Text size */
    border-radius: 5px; /* Rounded corners */
    display: inline-block; /* Ensures the dropdown behaves as an inline block */
    width: 100%; /* Full width */
    text-align: left; /* Aligns text to the left */
}

.btn-dropdown:hover {
    background-color: #e6e6e6; /* Slightly darker grey on hover */
    cursor: pointer; /* Changes cursor to pointer on hover */
}

.btn-dropdown:focus {
    background-color: #d9d9d9; /* Even darker grey on focus */
    outline: none; /* Removes outline on focus */
    box-shadow: 0 0 3px #666; /* Adds focus shadow */
}

/* Placeholder style for the property type button */
button#dropdownMenuButton {
    color: #999; /* Placeholder color */
    background-color: #f2f2f2; /* Light background */
    border: none; /* Remove border */
    outline: none; /* Remove the default outline */
    box-shadow: none; /* Remove any focus box-shadow */
}

/* When a property type is selected, change the color */
button#dropdownMenuButton.selected {
    color: #333; /* Regular text color when selected */
}

/* Ensure the placeholder color and border stay consistent on focus */
button#dropdownMenuButton:focus {
    color: #999; /* Keep placeholder color on focus */
    border: none; /* Prevent border on focus */
    outline: none; /* Prevent outline on focus */
    box-shadow: none; /* Remove the focus box-shadow */
}
</style>