<template>    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> Edit Property Listing</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>2/6</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        <!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            
                            <li><router-link :to="`/post-property-listing/${itemId}`">Step 1</router-link></li>
                            <li><a class="active" href="javascript:;">Step 2</a></li>
                            <li><router-link :to="`/post-property-listing-3/${itemId}`">Step 3</router-link></li>
                            <li><router-link :to="`/post-property-listing-4/${itemId}`">Step 4</router-link></li>
                            <li><router-link :to="`/post-property-listing-5/${itemId}`">Step 5</router-link></li>
                            
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="property-box">
                        <form @submit.prevent="submitPost">  
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Property Details</h3>
                                </div>								
                                <div class="row row-cols-2">
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Features*</label>
                                            <select id="feature_ids" v-model="form.feature_ids" class="form-control" multiple>
                                                <!-- <option value="" disabled selected>Please Features</option> -->
                                                <option v-for="option in optionsFeatures" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span class="info">*Can be selected multiple options</span>
                                            <div  class="error" v-if="errors.feature_ids">{{ errors.facilities_ids }}</div>
                                        </div>
                                    </div>                               
                                    <div class="col">
                                        <div class="form-group">
                                            <label></label>
                                            <button type="button" class="btn comman-btn-2 w-100 popupOpen" data-bs-toggle="modal" data-bs-target="#add-feature-modal">Add Features <img src="frontend/images/plus-circal.svg" alt=""></button>
                                            <div class="badge-list-post listofAddtional_features">
                                                <span v-for="option in additionalFeatureList" :key="option.id" :value="option.id" 
                                                :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}"
                                                >
                                                    {{option.additional_feature_name}} 
                                                    <span class="fa fa-trash text-white  deletefeature" data-deleteType="feature" :id="option.id">
                                                        <span class="visually-hidden">X</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        
                                    </div> 									                                 
                                </div>
                                <div class="row row-cols-2">
                                    
                                    <div class="col">
                                        <div class="form-group">
                                            <label>Facilities</label>
                                            <select id="facilities_ids" v-model="form.facilities_ids" class="form-control" multiple >
                                                <!-- <option value="" disabled selected>Please Facilities</option> -->
                                                <option v-for="option in optionsFacilities" :key="option.value" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                            </select>
                                            <span class="info">*Can be selected multiple options</span>
                                            <div  class="error" v-if="errors.facilities_ids">{{ errors.facilities_ids }}</div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label></label>
                                            <button type="button"  class="btn comman-btn-2 w-100 popupOpen" data-bs-toggle="modal" data-bs-target="#add-facility-modal">Add Facilities <img src="frontend/images/plus-circal.svg" alt=""></button>
                                            <div class="badge-list-post listofAddtional_facility">
                                                <span v-for="option in additionalFacilityList" :key="option.id" :value="option.id" 
                                                    :class="{'badge': true, 'bg-secondary': true, 'rounded-pill': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}">
                                                        {{option.additional_facility_name}}
                                                        <a class="fa fa-trash text-white deletefeature" data-deleteType="facility"  :id="option.id"></a>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- <span v-for="option in additionalFacilityList" :key="option.id" :value="option.id" type="button" 
                                        :class="{'btn': true, 'btn-primary': true, [`addi_${option.id}`]: true, 'position-relative': true, 'featurelist-badge': true}">
                                            {{option.additional_facility_name}}
                                            <span class="fa fa-trash position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle deletefeature" data-deleteType="facility"  :id="option.id">
                                                <span class="visually-hidden">X</span>
                                            </span>
                                        </span> -->
                                    </div> 									                                 
                                </div>								
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Furnishing & Fittings</label>
                                        <div v-for="item in checkboxFFlist" :key="item.id"  class="form-check form-check-inline">
                                            <input class="form-check-input"  type="radio" 
                                            :id="item.id"
                                            :value="item.id"
                                            v-model="form.pr_master_furnishing_fitting_id">
                                            <label class="form-check-label w-100" :for="item.id">
                                                {{ item.name }}
                                            </label>
                                        </div>
                                        <span  class="error" v-if="errors.pr_master_furnishing_fitting_id">{{ errors.pr_master_furnishing_fitting_id }}</span>
                                    </div>
                                </div>
                                <!-- <div class="row row-cols-2" bis_skin_checked="1">
									<div class="col" bis_skin_checked="1">
										<div class="form-group" bis_skin_checked="1">
											<label>Included F&amp;F Package</label>
											<select class="form-control">
												<option>Included F&amp;F Package</option>
											</select>
											<span class="info">*Can be selected multiple options</span>
										</div>
									</div>									 
									<div class="col" bis_skin_checked="1">
										<div class="form-group" bis_skin_checked="1">
											<label></label>
											<button class="btn comman-btn-2 w-100">Add Furniture &amp; Fittings <img src="images/plus-circal.svg" alt=""></button>
										</div>
									</div> 
								</div> -->
                                <div class="form-group ai-textarea">
                                    <label>Description</label>
                                    <textarea  @input="updateWordCount" @paste="handlePaste" class="form-control" v-model="form.additional_description" placeholder="Description" rows="5">Write an attention-grabbing description about this property listing, highlight its unique features or hidden-value, it must be exciting for potential buyers or renters to want to enquire more and contact me. Include some nearby amenities and neighbourhood details, convenience and perks that home-buyers would love about it. Emphasize on the advantages of the property and present it in a professional format, but make it brief and sounds interesting!</textarea>
                                    <span> {{ wordCount }}/1,000 words</span>
                                    <span  class="error" v-if="errors.additional_description">{{ errors.additional_description }}</span>
                                </div>
                            </div> 
                            <div class="property-inner-box">
                                <div class="comman-title">
                                    <h3>Pricing Terms & Payment Options <i class="fa fa-question-circle"></i></h3>
                                </div>								
                                <div class="form-group">
                                    <label>Asking Sale Price</label>
                                    <input  type="number" class="form-control" v-model="form.asking_price_sale_rent" placeholder="Asking Sale Price">											
                                    <span  class="error" v-if="errors.asking_price_sale_rent">{{ errors.asking_price_sale_rent }}</span>
                                </div>								
                                <div class="form-group">
                                    <label for="">Payment Mode Acceptance</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Cheque-Bank-Transfer" id="Cheque-Bank-Transfer">
                                        <label class="form-check-label w-100" for="Cheque-Bank-Transfer">
                                            Cheque/Bank Transfer
                                        </label>
                                    </div>	
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Debit-Credit-Card" id="Debit-Credit-Card">
                                        <label class="form-check-label w-100" for="Debit-Credit-Card">
                                            Debit/Credit Card
                                        </label>
                                    </div>	
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="eWallet" id="eWallet">
                                        <label class="form-check-label w-100" for="eWallet">
                                            eWallet
                                        </label>
                                    </div>	
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Digital-Currency" id="Digital-Currency">
                                        <label class="form-check-label w-100" for="Digital-Currency">
                                            Digital Currency
                                        </label>
                                    </div>	
                                </div>
                            
                                <div class="row row-cols-2" v-if="isRental">
                                    <div class="form-group">
                                        <label>Rental Period / Preferred Tenancy (If Applicable)</label>
                                        <select 
                                            id="group1Select" 
                                            class="form-control"
                                            v-model="form.rental_period_preferred_tenancy" 
                                        >
                                        <option value="" selected>Rental Period / Preferred Tenancy (If Applicable)</option>
                                            <option 
                                            v-for="option in rentalPriodLIst" 
                                            :key="option.value" 
                                            :value="option.value"
                                            >
                                            {{ option.label }}
                                            </option>
                                        </select>
                                        <span  class="error" v-if="errors.rental_period_preferred_tenancy">{{ errors.rental_period_preferred_tenancy }}</span>
                                    </div>
                                </div>    
                                <div class="row row-cols-2" v-if="isRental">
                                    
                                        <!-- <label>Rental Term (If Applicable)</label> -->
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Security Deposit</label>
                                                <input type="number" min="0" v-model="form.rental_security_deposit" class="form-control" placeholder="Security Deposit">
                                                <span  class="error" v-if="errors.rental_security_deposit">{{ errors.rental_security_deposit }}</span>
                                            </div>	
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Utility Deposit</label>
                                                <input type="number" min="0" v-model="form.rental_utility_deposit" class="form-control" placeholder="Utility Deposit">
                                                <span  class="error" v-if="errors.rental_utility_deposit">{{ errors.rental_utility_deposit }}</span>
                                            </div>	
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Lump-Sum Pre-Pay Rent</label>
                                                <input type="number" min="0" v-model="form.rental_lumpsum_pre_pay_in_months_rent" class="form-control" placeholder="Lump-Sum Pre-Pay Rent">
                                                <span  class="error" v-if="errors.rental_lumpsum_pre_pay_in_months_rent">{{ errors.rental_lumpsum_pre_pay_in_months_rent }}</span>
                                            </div>	
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Proposed Pre-Pay Rebate</label>
                                                <input
                                                    class="form-control"
                                                    type="number" 
                                                    id="numberInput"
                                                    v-model="form.rental_proposed_pre_pay_rebate_in_percentage"
                                                    :min="0"
                                                    :max="100"
                                                    @input="validateInput"
                                                    @keypress="handleKeypress"
                                                    placeholder="0-100"
                                                    />
                                                <!-- <input required type="number" min="0" max="100"  v-model="form.rental_proposed_pre_pay_rebate_in_percentage" class="form-control" placeholder="Proposed Pre-Pay Rebate"> -->
                                                <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
                                                <span  class="error" v-if="errors.rental_proposed_pre_pay_rebate_in_percentage">{{ errors.rental_proposed_pre_pay_rebate_in_percentage }}</span>
                                            </div>	
                                        </div>
                                </div>
                                <div class="row row-cols-1" v-if="isRental">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Diversity Friendly</label>
                                                <input type="checkbox"  class="form-check-input" v-model="form.rental_diversity_friendly" value="1">
                                                All renters are welcome regardless of race, ethnicity, religion, age, gender identity, sexual orientation or physical ability.
                                                <span  class="error" v-if="errors.rental_diversity_friendly">{{ errors.rental_diversity_friendly }}</span>
                                            </div>	
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Pet Policy</label>
                                                <select class="form-control" v-model="form.rental_pet_allowed" required>
                                                    <option value="1">Pets Allowed</option>
                                                    <option value="2">Pets Not-Allowed</option>
                                                </select>
                                                <span  class="error" v-if="errors.rental_pet_allowed">{{ errors.rental_pet_allowed }}</span>
                                            </div>	
                                        </div>
                                    
                                    <!-- <div class="form-group">
                                        <label>Rental Term (If Applicable)</label>
                                        
                                        <select  name="cars" id="cars" class="form-control" v-model="form.rental_period_preferred_tenancy" >
                                            <optgroup label="Security Deposit">
                                                <option value="S$">S$</option>
                                            </optgroup>
                                            <optgroup label="Utility Deposit">
                                                <option value="S$">S$</option>
                                            </optgroup>
                                            <optgroup label="Lump-Sum Pre-Pay Rent">
                                                <option value="Mths">Mths</option>
                                            </optgroup>
                                            <optgroup label="Proposed Pre-Pay Rebate">
                                                <option value="%">%</option>
                                            </optgroup>
                                            <optgroup label="Diversity Friendly">
                                                <option value="All renters are welcome regardless of race">All renters are welcome regardless of race</option>
                                                <option value="ethnicity">ethnicity</option>
                                                <option value="religion">religion</option>
                                                <option value="age">age</option>
                                                <option value="gender">gender</option>
                                                <option value="sexual orientation or physical ability">sexual orientation or physical ability</option>
                                            </optgroup>
                                            <optgroup label="Pet Policy">
                                                <option value="mercedes">Pets Allowed</option>
                                                <option value="audi">Pets Not-Allowed</option>
                                            </optgroup>
                                        </select>
                                        <span  class="error" v-if="errors.listingType">{{ errors.listingType }}</span>
                                    </div> -->
                                </div> 
                                <div class="property-inner-box">
                                    <div class="comman-title">
                                        <h3>Property Status</h3>
                                    </div>				
                                    <div class="row row-cols-1 row-cols-md-2">
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Current Occupancy</label>
                                                <select class="form-control" v-model="form.current_occupancy" >
                                                    <option value="">Current Occupancy</option>
                                                    <option>Occupied By Owner</option>
                                                    <option>Rented To Tenant</option>
                                                    <option>Vacant</option>
                                                    <option>Renovation / Make-Over In Progress</option>
                                                </select>
                                                <span  class="error" v-if="errors.current_occupancy">{{ errors.current_occupancy }}</span>
                                            </div>	
                                        </div>									
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Current Monthly Rental</label>
                                                <input  type="number" min="0" class="form-control" v-model="form.current_tenancy_rental_rate" placeholder="Current Monthly Rental">
                                                <span  class="error" v-if="errors.current_tenancy_rental_rate">{{ errors.current_tenancy_rental_rate }}</span>
                                            </div>	
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Current Tenancy Expiry</label>
                                                <input type="date" class="form-control" v-model="form.current_tenancy_expiration_date" placeholder="Current Tenancy Expiry" >
                                            </div>	
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label for="">Availability Date</label>
                                                <input type="date" class="form-control" v-model="form.availability_date" placeholder="Availability Date" >
                                            </div>	
                                        </div>
                                    </div>														
                                </div> 
                            </div>
                            <div class="d-flex justify-content-md-end">
                                <!-- <button class="btn comman-btn-6">Previous</button> -->
                                <router-link :to="`/post-property-listing/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                                <button class="btn comman-btn-2 ms-3" type="submit">Next</button>
                            </div>
                        </form>  
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="add-feature-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">                           
                       
                        <div class="comman-title">
                            <h3>Add Features</h3>
                        </div>
                        <form @submit.prevent="submitAdditionalFF">
                            <div class="row row-cols-md-1">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Additional feature name</label>
                                        <input id="additional_feature_name" class="form-control" type="text" v-model="form.additional_feature_name" placeholder="Additional feature name">
                                        <span  class="error" v-if="errors.additional_feature_name">{{ errors.additional_feature_name }}</span>
                                    </div>
                                </div>                                   
                            </div> 
                            <button class="btn comman-btn-2" type="submit">Save</button>   
                        </form>                       
                        
                    </div>
                </div>
            </div>
        </div>
        
        <div class="modal fade" id="add-facility-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">			
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="frontend/images/close.svg" alt=""></button>			
                    <div class="modal-body">                           
                       
                        <div class="comman-title">
                            <h3>Add facility</h3>
                        </div>
                        <form @submit.prevent="submitAdditionalFacility">
                            <div class="row row-cols-md-1">
                                <div class="col">
                                    <div class="form-group">
                                        <label>Additional facility name</label>
                                        <input class="form-control" id="additional_facility_name" type="text" v-model="form.additional_facility_name" placeholder="Additional facility name">
                                        <span  class="error" v-if="errors.additional_facility_name">{{ errors.additional_facility_name }}</span>
                                    </div>
                                </div>                                   
                            </div> 
                            <button class="btn comman-btn-2" type="submit">Save</button>   
                        </form>                       
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import { OPTIONS_GROUP_1,RENTAL_PERIOD } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  import $ from 'jquery';
  export default {
    name: 'PostPropertyListingStep2', 
    data() {
        return {
            errorMessage :'',
            editData: '',
            isRental : false,
            optionsFeatures: [],
            optionsFacilities: [],
            additionalFeatureList: [],
            additionalFacilityList: [],
            checkboxFFlist: '',
            rentalPriodLIst: RENTAL_PERIOD,

            optionsGroup1: OPTIONS_GROUP_1,
            wordCount: 0,
            form: {
                feature_ids: [],
                facilities_ids: [],
                furnishing_fitting_ids: [],
                additional_description:'',
                asking_price_sale_rent:'',
                pr_master_furnishing_fitting_id:'',
                mode_of_payment:[],
                rental_period_preferred_tenancy:'',
                rental_lumpsum_pre_pay_in_months_rent:'',
                rental_proposed_pre_pay_rebate_in_percentage:'',
                rental_security_deposit:'',
                rental_utility_deposit:'',
                rental_diversity_friendly:'0',
                rental_pet_allowed:'1',
                current_occupancy:'',
                availability_date:'',
                current_tenancy_rental_rate:'',
                current_tenancy_expiration_date:'',
                // checkedFF: '',
                // property_name: '',
                // qualify_for_foreigner: '0',
                // listingType: '',
                // rentalPriodLIst: ''
            },            
            errors: {}
        }
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
      itemId() {
        return this.$route.params.id;
      }
    },
    created() {
        // toastr.success('Your action was successful!', 'Success', {
        //     closeButton: true,
        //     debug: false,
        //     progressBar: true,
        //     positionClass: 'toast-top-right',
        //     onclick: null,
        //     showDuration: '300',
        //     hideDuration: '1000',
        //     timeOut: '50000',
        //     extendedTimeOut: '1000',
        //     showEasing: 'swing',
        //     hideEasing: 'linear',
        //     showMethod: 'fadeIn',
        //     hideMethod: 'fadeOut'
        // });
        //toastr.success('vv', 'Success', {progressBar: true, closeButton: true});
        this.fetchOptions();
    },
    methods: {

        validateForm() {
            console.log(this.form.facilities_ids);
             this.errors = {};
             if (this.form.facilities_ids.length == 0) {
                 this.errors.facilities_ids = 'Facilities is required.';
             }
             if (this.form.feature_ids.length === 0) {
                 this.errors.feature_ids = 'Feature is required.';
             }
            //  console.log(this.form.asking_price_sale_rent);
             if (this.form.asking_price_sale_rent == 0) {
                 this.errors.asking_price_sale_rent = 'Asking Sale Price is required.';
             }
             
              console.log(this.form.pr_master_furnishing_fitting_id);
             if (this.form.pr_master_furnishing_fitting_id == 0) {
                 this.errors.pr_master_furnishing_fitting_id = 'Furnishing & Fittings is required.';
             }
             if(this.isRental){
                if (!this.form.rental_period_preferred_tenancy) {
                    this.errors.rental_period_preferred_tenancy = 'Rental Period / Preferred Tenancy is required.';
                }
                if (this.form.rental_security_deposit.length == 0) {
                    this.errors.rental_security_deposit = 'Security Deposit is required.';
                }
                if (this.form.rental_utility_deposit.length == 0) {
                    this.errors.rental_utility_deposit = 'Utility Deposit is required.';
                }
                if (this.form.rental_lumpsum_pre_pay_in_months_rent.length == 0) {
                    this.errors.rental_lumpsum_pre_pay_in_months_rent = 'Lump-Sum Pre-Pay Rent is required.';
                }
                if (this.form.rental_proposed_pre_pay_rebate_in_percentage.length == 0) {
                    this.errors.rental_proposed_pre_pay_rebate_in_percentage = 'Proposed Pre-Pay Rebate is required.';
                }
            }
             
             if (!this.form.current_occupancy) {
                 this.errors.current_occupancy = 'Current Occupancy is required.';
             }
            return Object.keys(this.errors).length === 0;
            //return 1
        },        
        validateAddionalFF() {            
            this.errors = {};
            if (!this.form.additional_feature_name) {
                this.errors.additional_feature_name = 'This field is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        validateAddionalFacility() {            
            this.errors = {};
            if (!this.form.additional_facility_name) {
                this.errors.additional_facility_name = 'This field is required.';
            }
            return Object.keys(this.errors).length === 0;
        },        
        async submitPost() {
            
            //this.$router.push('/post-property-listing-3/'+this.itemId);         
            if (this.validateForm()) {
                //var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.loading) return;
                this.loading = true;
                try {
                    
                    var facilities_ids = this.form.facilities_ids.join(',');
                    var feature_ids = this.form.feature_ids.join(',');
                    var furnishing_fitting_ids = this.form.furnishing_fitting_ids.join(',');
                    const response = await this.$axios.post('api/update-property-details', {
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,
                        feature_ids: feature_ids, 
                        facilities_ids: facilities_ids,                        
                        pr_master_furnishing_fitting_id:this.form.pr_master_furnishing_fitting_id,
                        furnishing_fitting_ids:furnishing_fitting_ids,
                        additional_description:this.form.additional_description,
                        asking_price_sale_rent:this.form.asking_price_sale_rent,
                        mode_of_payment:this.form.mode_of_payment,
                        rental_period_preferred_tenancy:this.form.rental_period_preferred_tenancy ? this.form.rental_period_preferred_tenancy : 0,
                        rental_security_deposit:this.form.rental_security_deposit ? this.form.rental_security_deposit : 0,
                        rental_utility_deposit:this.form.rental_utility_deposit ? this.form.rental_utility_deposit : 0,
                        rental_lumpsum_pre_pay_in_months_rent:this.form.rental_lumpsum_pre_pay_in_months_rent ? this.form.rental_lumpsum_pre_pay_in_months_rent : 0,
                        rental_proposed_pre_pay_rebate_in_percentage:this.form.rental_proposed_pre_pay_rebate_in_percentage ? this.form.rental_proposed_pre_pay_rebate_in_percentage : 0,
                        rental_diversity_friendly:this.form.rental_diversity_friendly == true ? 1 : 0,
                        rental_pet_allowed:this.form.rental_pet_allowed,
                        current_occupancy:this.form.current_occupancy,
                        availability_date:this.form.availability_date,
                        current_tenancy_rental_rate:this.form.current_tenancy_rental_rate ? this.form.current_tenancy_rental_rate : 0,
                        current_tenancy_expiration_date:this.form.current_tenancy_expiration_date,
                    });
                    
                    // toast.success(response.data.message, 'Success', { timeOut: 5000 });
                     toastr.success(response.data.message, 'Success', {progressBar: true, closeButton: true});
                    // console.log('Login successful:', response.data);
                    // console.log('id of posted', response.data.output.id);
                    var property_listing_id = response.data.output.id
                    this.$router.push('/post-property-listing-3/'+property_listing_id);         

                } catch (error) {
                    
                    toastr.error('Something went wrong in API',{progressBar: true, closeButton: true})

                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        },  

        async fetchOptions() {

            const token = localStorage.getItem('userToken');
            // Get Features list
            try {
                const response = await this.$axios.post('api/pr-master-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFeatures = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Get facilities list
            try {
                const response = await this.$axios.post('api/pr-master-facilities-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsFacilities = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            
            // Furnishing & Fittings
            try {
                const response = await this.$axios.post('api/pr-master-furnishing-fittings-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_checkbox: 1,
                    show_in_property:1,
                    show_in_included : 0,                     
                    last_record_number : 0                     
                });
                this.checkboxFFlist = response.data.output.map(item => ({
                    id: item.id,
                    name: item.name
                }));
                console.log(this.checkboxFFlist);
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // If Edit
            if(this.itemId > 0){
                
                try {
                    const response = await this.$axios.post('api/property-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        property_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    console.log(this.editData);
                    if(this.editData.listing_type == '2' || this.editData.listing_type == '3' || this.editData.listing_type == '6'){
                        this.isRental = true;
                    }
                    this.form.additional_description = this.editData.additional_description;
                    const SeletedfeatureIds = this.editData.features.map(item => item.pr_master_feature_id);
                    this.form.feature_ids = SeletedfeatureIds;
                    
                    const Seletedfacilities_ids = this.editData.facilities.map(item => item.pr_master_facilty_id);
                    this.form.facilities_ids = Seletedfacilities_ids;

                    this.form.pr_master_furnishing_fitting_id = this.editData.pr_master_furnishing_fitting_id.toString();

                    const selectedfurnishing_fitting_ids = this.editData.furnishings_fittings.map(item => item.pr_master_furnishing_fitting_id);
                    this.form.furnishing_fitting_ids = selectedfurnishing_fitting_ids;

                    this.form.asking_price_sale_rent = this.editData.asking_price_sale_rent;
                    
                    if (this.editData.mode_of_payment) {
                        this.form.mode_of_payment = JSON.parse(this.editData.mode_of_payment);
                    }
                    

                    this.form.rental_period_preferred_tenancy = this.editData.rental_period_preferred_tenancy;
                    this.form.rental_security_deposit = this.editData.rental_security_deposit;
                    this.form.rental_utility_deposit = this.editData.rental_utility_deposit;
                    this.form.rental_lumpsum_pre_pay_in_months_rent = this.editData.rental_lumpsum_pre_pay_in_months_rent;
                    this.form.rental_proposed_pre_pay_rebate_in_percentage = this.editData.rental_proposed_pre_pay_rebate_in_percentage;
                    this.form.rental_diversity_friendly = this.editData.rental_diversity_friendly ? true : false;
                    this.form.rental_pet_allowed = this.editData.rental_pet_allowed;
                    this.form.current_occupancy = this.editData.current_occupancy;
                    if(this.editData.availability_date){
                        this.form.availability_date = this.editData.availability_date.split(' ')[0];
                    }
                    this.form.current_tenancy_rental_rate = this.editData.current_tenancy_rental_rate;
                    if(this.editData.current_tenancy_expiration_date){
                        this.form.current_tenancy_expiration_date = this.editData.current_tenancy_expiration_date.split(' ')[0];
                    }

                    this.additionalFeatureList = this.editData.features_additional.map(item => ({
                        id: item.id,
                        pr_master_feature_id: item.pr_master_feature_id,
                        additional_feature_name: item.additional_feature_name
                    }));
                    this.additionalFacilityList = this.editData.facility_additional.map(item => ({
                        id: item.id,
                        pr_master_facilty_id: item.pr_master_facilty_id,
                        additional_facility_name: item.additional_facility_name
                    }));

                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
            // If Edit
        },

        async submitAdditionalFF() {
            
            if (this.validateAddionalFF()) {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.post('api/add-property-listing-features-additionals', {
                                user_id:this.user.id,
                                api_token:token,                    
                                property_listing_id: this.itemId,
                                additional_feature_name: this.form.additional_feature_name
                    });
                    var res_data = response.data.output;
                    toastr.success('Featured added', 'Success', {progressBar: true, closeButton: true});
                    setTimeout(function(){
                        $(".btn-close").trigger('click');


                        var html = '<span class="badge bg-secondary rounded-pill addi_'+res_data.id+' position-relative featurelist-badge" value="'+res_data.id+'">'+res_data.additional_feature_name+' \
                                        <a class="fa fa-trash text-white deletefeature" data-deletetype="feature" id="'+res_data.id+'"></a>\
                                    </span>';
                        window.$('.listofAddtional_features').append(html);

                    },500);
                } catch (error) {
                            
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },
        
        async submitAdditionalFacility() {
            
            if (this.validateAddionalFacility()) {
                try {
                    const token = localStorage.getItem('userToken');
                    const response = await this.$axios.post('api/add-property-listing-facilities-additionals', {
                                user_id:this.user.id,
                                api_token:token,                    
                                property_listing_id: this.itemId,
                                additional_facility_name: this.form.additional_facility_name
                    });
                    var res_data = response.data.output;
                    toastr.success('Facility added', 'Success', {progressBar: true, closeButton: true});
                    setTimeout(function(){
                        $(".btn-close").trigger('click');
                        var html = '<span class="badge bg-secondary rounded-pill addi_'+res_data.id+' position-relative featurelist-badge" value="'+res_data.id+'">'+res_data.additional_facility_name+' \
                                        <a class="fa fa-trash text-white deletefeature" data-deletetype="facility" id="'+res_data.id+'"></a>\
                                    </span>';
                        window.$('.listofAddtional_facility').append(html);

                    },500);
                } catch (error) {
                            
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.loading = false;
                }
            }
        },

        async deleteAddtional(typeDelete,id) {

            var api_name = 'api/delete-property-listing-facilities-additionals';
            var feature_id = 0;
            var facility_id = 0;
            if(typeDelete == 'feature'){
                api_name = 'api/delete-property-listing-features-additionals';
                feature_id = id;
            }else{
                facility_id = id;
            }
            const token = localStorage.getItem('userToken');
            try {
                await this.$axios.post(api_name,
                {                    
                    api_token: token,
                    user_id :this.user.id,
                    feature_id: feature_id,
                    facility_id:facility_id 
                });
                $(".addi_"+id).remove();
                toastr.success('Deleted', 'Success', {progressBar: true, closeButton: true});
                //console.log(response);
                
            } catch (error) {
                
                // console.error('Error fetching options:', error);
                toastr.error('Error fetching options:')
            }
        },


        validateInput() {
            if (this.form.rental_proposed_pre_pay_rebate_in_percentage === '') {
                this.errorMessage = '';
                return;
            }
            const num = parseFloat(this.form.rental_proposed_pre_pay_rebate_in_percentage);
            if (isNaN(num) || num < 0 || num > 100) {
                this.errorMessage = `Please enter a number between 1 and 100.`;
            } else {
                this.errorMessage = '';
            }
        },
        handleKeypress(event) {
            const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
            if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },        
        updateWordCount(event) {
            let inputText = event.target.value;
            const count = this.calculateWordCount(inputText);                
            if (count > 1000) {
                this.form.additional_description = this.truncateText(inputText, 1000);
            }
            this.text = inputText;
            this.wordCount = this.calculateWordCount(this.text);
        },
        calculateWordCount(text) {
            if (typeof text === 'string') {
                return text.trim().split(/\s+/).filter(Boolean).length;
            }
            return 0;
        },
        truncateText(text, maxWords) {
            const words = text.trim().split(/\s+/).filter(Boolean);
            return words.slice(0, maxWords).join(' ');
        },
        handlePaste(event) {
                event.preventDefault();
                const pasteData = (event.clipboardData || window.clipboardData).getData('text');
                let newText = this.text + (this.text ? ' ' : '') + pasteData;
                this.enforceWordLimit(newText);
        },
        enforceWordLimit(newText) {
            if (this.calculateWordCount(newText) > 1000) {
                newText = this.truncateText(newText, 1000);
            }
            this.form.additional_description = newText;
            this.wordCount = this.calculateWordCount(newText);
        },

        
        setupEventListeners() {
            const self = this; // Save reference to `this`
            
            $(document).on('click', '.deletefeature', function() {        
                const deleteType = $(this).data('deletetype');
                const id = $(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete ${deleteType}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteAddtional(deleteType, id);
                }
                });
            });
        }
    

      
    },
    mounted() {
        this.setupEventListeners();
    }   
    
  }

//   window.$(document).on("click",".deletefeature",function(){        

//     var deleteType =  window.$(this).data('deletetype');
//     var id =  window.$(this).data('id');


//         window.Swal.fire({
//         title: "Are you sure?" ,
//         text: "Delete " + deleteType,
//         icon: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes, delete it!"
//         }).then((result) => {
//         if (result.isConfirmed) {

//             this.deleteAddtional(deleteType,id);
//             window.Swal.fire({
//             title: "Deleted!",
//             text: "Your file has been deleted.",
//             icon: "success"
//             });
//         }
//         });

//   })
 
  window.$(document).on("click",".popupOpen",function(){        
    window.$('#additional_feature_name').val('');
    window.$('#additional_facility_name').val('');
  })
  </script>
  
  <style scoped>
  .deletefeature{
    cursor: pointer;
  }
  .badge{
    margin-right: 1px;
  }
  .badge-list-post{
    gap: 2px;
    display: flex;
    align-content: stretch;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
  }
  /* .featurelist-badge{
    margin-left: 10px;
  }
  .featurelist-badge .rounded-circle{
    font-size: 12px;
  } */
  /* Add styles specific to the About Us page here */
  </style>
  