<template>    
    
    <section class="property-se">
        <div class="container">
            <div class="property-title-bar">
                <div class="comman-title mb-0">
                    <h3 v-if="this.itemId>0"><a href="#"><i class="fa fa-angle-left"></i></a> Edit Property Listing</h3>
                    <h3 v-if="this.itemId==0"><a href="#"><i class="fa fa-angle-left"></i></a> Create Property Listing</h3>
                </div>
                <div class="progress-bar-box">
                    <div>5/5</div>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
                        <div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <div class="property-sidebar">
                        <ul>
                            <li><router-link :to="`/post-property-listing/${itemId}`">Step 1</router-link></li>
                            <li><router-link :to="`/post-property-listing-2/${itemId}`">Step 2</router-link></li>
                            <li><router-link :to="`/post-property-listing-3/${itemId}`">Step 3</router-link></li>
                            <li><router-link :to="`/post-property-listing-4/${itemId}`">Step 5</router-link></li>	 
                            <li><a class="active" href="javascript:;">Step 5</a></li>                            
                        </ul>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="property-box mb-4">							 
                        <div class="property-inner-box mb-0">
                            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4">
                                
                                <div class="col">
                                    <div class="form-group">
                                        <router-link :to="`/property-detail/${itemId}`"  class="comman-btn-2" target="_blank" > <img src="frontend/images/eye.svg" alt=""> Preview</router-link>
                                        <!-- <a href="javascript:;" @click="save()" class="comman-btn-2" ><img src="frontend/images/eye.svg" alt=""> Preview</a> -->
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <a href="javascript:;" @click="save('1')" class="comman-btn-2">Save & Publish</a>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <a href="javascript:;" @click="save(0)" class="comman-btn-1">Save As Draft</a>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <a href="javascript:;" @click="save()" class="comman-btn-1">Copy-Posting</a>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="d-flex justify-content-md-end">
                            <!-- <button class="btn comman-btn-6">Previous</button> -->
                            <router-link :to="`/post-property-listing-4/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                            <!-- <button class="btn comman-btn-2 ms-3">Next</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
  
  <script>
  import { mapState } from 'vuex';
  import toastr from 'toastr';
  import 'toastr/build/toastr.min.css';
  
  export default {
    name: 'PostPropertyListingStep5', 
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user']),
        itemId() {
            return this.$route.params.id;
        }
    },
    methods: {
      
        // async save() {    
        //     alert('hi');        
        //     // this.$router.push('/post-property-listing-5/'+this.itemId);  
        // }, 

        async save(params) {
            
            const token = localStorage.getItem('userToken');
            try {
                    
                const response = await this.$axios.post('api/update-property-publish-status', {
                    user_id :this.user.id,
                    api_token: token,
                    property_listing_id:this.itemId,
                    publish_status:params
                });                    
                toastr.success(response.data.message, 'Success', { timeOut: 3000 });
                this.$router.push('/my-property');         

            } catch (error) {
                
                if (error.response && error.response.data) {
                    this.errors = this.extractErrors(error.response.data.errors || {});
                } else {
                    console.error('An unexpected error occurred:', error);
                }
            } finally {
                this.loading = false;
            }

        },
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        }, 
     
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  