<template>
  <section class="agent-pages-se">
    <div class="container">
      <div class="comman-title text-center">
        <h2>Search for a Service Provider</h2>
      </div>
      <div class="agent-search-box">
        <div class="row row-cols-1 row-cols-sm-3 row-cols-md-5">
          <div class="col">
            <div class="form-group">
              <select v-model="filters.type_of_service" class="form-control" @change="fetchAgents()">
                <option value="0">Type of Service</option>
                <option v-for="service in services" :key="service.id" :value="service.id">
                  {{ service.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <select v-model="filters.exp_in_yrs" class="form-control" @change="fetchAgents()">
                <option value="0-99">Years Experience</option>
                <option value="0-2">Less than 2 Years</option>
                <option value="2-4">2 - 4 Years</option>
                <option value="5-9">5 - 9 Years</option>
                <option value="10-19">10 - 19 Years</option>
                <option value="20-99">Above 20 Years</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="dropdown">
                <button
                  class="form-control dropdown-toggle"
                  type="button"
                  id="availabilityDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Select Availability
                </button>
                <ul class="dropdown-menu" aria-labelledby="availabilityDropdown">
                  <li v-for="(day, index) in days" :key="index" class="dropdown-item">
                    <input
                      type="checkbox"
                      :id="day.key"
                      :value="1"
                      :checked="filters[day.key] === 1"
                      @change="handleCheckboxChange(day.key, $event)"
                    />
                    <label :for="day.key">&nbsp;{{ day.label }}</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <select v-model="filters.rating" class="form-control" @change="fetchAgents()">
                <option value="0-5">Rating</option>
                <option value="0-3">Less than 3 Stars</option>
                <option value="3-3.99">3 - 3.99 Stars</option>
                <option value="4-5">4 - 5 Stars</option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <select v-model="filters.location" class="form-control" @change="fetchAgents">
                <option value="0">Location</option>
                <option v-for="location in locations" :key="location.id" :value="location.name">
                  {{ location.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col col-12">
            <div class="input-group mb-0">
              <input
                v-model="filters.keyword"
                type="text"
                class="form-control"
                placeholder="Enter Specific Name, Description, Agency, Company"
              />
              <button class="comman-btn-2" @click.prevent="fetchAgents()">
                <img src="frontend/images/search-ico.svg" alt=""><b> Search</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="filter-bar-se">
        <div class="comman-title">
          <h3>List Of Service Providers</h3>
        </div>
        <div class="filter-right-bar">
          <div class="dropdown">
            <a href="#" id="Dropdown-sort-by" role="button" data-bs-toggle="dropdown" aria-expanded="true">
              <img src="frontend/images/filter-ico.svg"> Sort By
            </a>
            <ul class="dropdown-menu" aria-labelledby="Dropdown-sort-by">
              <li><a class="dropdown-item" href="#" @click.prevent="sortAgents('Name')">Name</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="sortAgents('Newest')">Newest</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="sortAgents('Years Of Experience')">Years Of Experience</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 row-cols-sm-2">
        <div v-if="agents.length === 0">
          No records found.
        </div>
        <div v-else class="col" v-for="agent in agents" :key="agent.id">
          <div class="agent-bx">
            <div class="agent-pic">
              <div class="heart-pro">
                <a href=""><i class="fa fa-heart-o"></i></a>
              </div>
              <div class="mark-logo">
                <img src="frontend/images/logo.png">
              </div>
              <picture>
                <router-link :to="{ name: 'AgentDetail', params: { id: agent.id } }">
                  <img :src="getUserProfileImage(agent?.profile_photo_name)" />
                </router-link>
              </picture>
            </div>
            <div class="agent-info">
              <div class="row row-cols-1">
                <div class="col col-7">
                  <h4>{{ agent.name }} <img src="frontend/images/varify-user.svg" alt=""></h4>
                  <p><span>@{{ agent.user_name_url }}</span></p>
                  <p>Position: {{ agent?.user_service_details?.position_held }}</p>
                  <p>{{ formatPhoneNumber(agent.contact_phone) }}</p>
                </div>
                <div class="col col-5">
                  <p>Join {{ yearsAgo(agent?.user_service_details?.personal_working_since) }}</p>
                  <p><img src="frontend/images/star.svg" alt=""> {{ agent?.user_service_details?.total_rating_points }} Ratings</p>
                  <p><img src="frontend/images/thumbs-up-star.svg" alt=""> {{ agent?.user_service_details?.total_reviews }} reviews</p>
                  <p><img src="frontend/images/handshakes.svg" alt=""> {{ agent?.user_service_details?.total_digital_handshakes }} Handshakes</p>
                </div>
              </div>
              <router-link :to="{ name: 'AgentDetail', params: { id: agent.id } }" class="comman-btn-2 w-100">
                <b> View Profile</b>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-se">
        <nav aria-label="...">
          <ul class="pagination justify-content-end">
            <li
              class="page-item"
              :class="{ disabled: filters.page === 1 }"
              @click="filters.page > 1 && changePage(filters.page - 1)"
              v-if="filters.page > 1"
            >
              <a class="page-link"><img src="frontend/images/left-arrow-icon.svg" alt="Previous"></a>
            </li>
            <li
              v-for="pageNumber in paginatedPages(filters.page, totalPages)"
              :key="pageNumber"
              class="page-item"
              :class="{ active: pageNumber === filters.page }"
            >
              <a
                class="page-link"
                @click.prevent="pageNumber !== filters.page && changePage(pageNumber)"
              >
                {{ pageNumber }}
              </a>
            </li>
            <li
              class="page-item"
              :class="{ disabled: filters.page === totalPages }"
              @click="filters.page < totalPages && changePage(filters.page + 1)"
            v-if="filters.page < totalPages"
            >
              <a class="page-link"><img src="frontend/images/right-arrow-icon.svg" alt="Next"></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { CONSTANT } from "@/constants/constants";
import { formatPhoneNumber, getUserProfileImage, paginatedPages, yearsAgo } from "@/utils/helpers";

export default {
  data() {
    return {
      agents: [],
      totalPages: 1,
      totalRecords: 1,
      locations: [],
      services: [],
      days: [
        { key: 'availability_monday', label: 'Monday' },
        { key: 'availability_tuesday', label: 'Tuesday' },
        { key: 'availability_wednesday', label: 'Wednesday' },
        { key: 'availability_thursday', label: 'Thursday' },
        { key: 'availability_friday', label: 'Friday' },
        { key: 'availability_saturday', label: 'Saturday' },
        { key: 'availability_sunday', label: 'Sunday' }
      ],
      filters: {
        page: 1,
        sort_by_field: 'Newest',
        keyword: '',
        exp_in_yrs: '0-99',
        rating: '0-5',
        location: 0,
        type_of_service: 0,
        availability_monday: 1,
        availability_tuesday: 1,
        availability_wednesday: 1,
        availability_thursday: 1,
        availability_friday: 1,
        availability_saturday: 1,
        availability_sunday: 1,
      }
    };
  },
  computed: {
    CONSTANT() {
      return CONSTANT
    },
    ...mapState(['user']),
  },
  async created() {
    await this.fetchAgents();
    await this.fetchLocations();
    await this.fetchServices();
  },
  methods: {
    paginatedPages,
    getUserProfileImage,
    yearsAgo,
    formatPhoneNumber,
    handleCheckboxChange(key, event) {
      this.filters[key] = event.target.checked ? 1 : 0;
      this.fetchAgents();
    },
    async fetchLocations() {
      try {
        const response = await this.$axios.post('/api/location', {
          parent_id: CONSTANT.COUNTRY_CODE,
          type: 'City',
          keyword: '',
        });
        this.locations = response.data.output;
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    },
    async fetchServices() {
      try {
        const response = await this.$axios.post('/api/services-type-list', {
          user_id: this.user.id,
          api_token: localStorage.getItem('userToken'),
        });
        this.services = response.data.output;
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    },
    async fetchAgents() {
      try {
        const agentsResponse = await this.$axios.post('/api/agent-search-browse', {
          user_id: this.user.id,
          type_of_service: this.filters.type_of_service,
          exp_in_yrs: this.filters.exp_in_yrs,
          availability_monday: this.filters.availability_monday,
          availability_tuesday: this.filters.availability_tuesday,
          availability_wednesday: this.filters.availability_wednesday,
          availability_thursday: this.filters.availability_thursday,
          availability_friday: this.filters.availability_friday,
          availability_saturday: this.filters.availability_saturday,
          availability_sunday: this.filters.availability_sunday,
          rating: this.filters.rating,
          location: this.filters.location,
          keyword: this.filters.keyword,
          sort_by_field: this.filters.sort_by_field,
          page_number: this.filters.page,
        });

        this.totalPages = agentsResponse.data.output.no_of_total_pages ?? 0;
        this.totalRecords = agentsResponse.data.output.no_of_total_records ?? 0;
        this.agents = agentsResponse.data.output.agent_info ?? [];
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    },
    sortAgents(sortBy) {
      this.filters.sort_by_field = sortBy;
      this.filters.page = 1;
      this.fetchAgents();
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.filters.page = page;
        this.fetchAgents();
      }
    },
  }
};
</script>
