export const sendCode = async (axios, mobileNumber) => {
  try {
    const response = await axios.post('api/code/send', {
      mobile_number: mobileNumber,
    });
    console.log('Code sent:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending code:', error);
    throw error;
  }
};
