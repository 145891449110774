<template>
  <div class="loader" v-if="isLoading">
    <div class="square"></div>
    <div class="square"></div>
    <div class="square"></div>
    <div class="square"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.square {
  width: 15px;
  height: 15px;
  margin: 5px;
  background-color: #DD8D00; /* Loader color */
  animation: rotate 0.6s infinite alternate;
}

.square:nth-child(2) {
  animation-delay: 0.2s;
}

.square:nth-child(3) {
  animation-delay: 0.4s;
}

.square:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
