// src/constants/constants.js

export const CONSTANT = {
    IMAGE_URL: 'https://pv-staging.tess.gg/storage',
    DEFAULT_IMAGE: 'frontend/images/no_profile.jpg',
    DEFAULT_NO_IMAGE: 'frontend/images/no_image_available.jpg',
    COUNTRY_CODE: '93',
    GOOGLE: {
      SITE_KEY: '6Lcg9j0qAAAAAKBi7MtKgoX4IfH99kjUK5URgYKW',
  }
  };
  