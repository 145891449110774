<template>
  <section class="payment-se">
    <div class="container">
      <div class="comman-title">
        <h3>Payment Method</h3>
      </div>
      <div class="payment-box">
        <div class="form-check mb-2">
          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault9" checked disabled>
          <label class="form-check-label w-100" for="flexCheckDefault9">
            <b>Online Banking</b> <span class="pull-right"><img src="frontend/images/visa.jpg" alt=""> <img src="frontend/images/master-card.png" alt=""></span>
          </label>
        </div>
        <p class="mb-0">You wil be redirected to a list of online banking website after submitting your order.</p>
      </div>
      <div class="d-md-flex protect-payment">
        <div class="d-flex align-items-center">
          <img src="frontend/images/lock.svg" class="me-3">
          <p class="mb-0">We protect your payment information using<br> encryption to provide bank-level security.</p>
        </div>
        <div class="ms-auto">
          <a href="#" class="comman-btn-2" @click.prevent="createPaymentRequest">
            <b>
              Pay S${{ parseFloat(packageDetails.annual_subscription).toFixed(packageDetails.annual_subscription % 1 !== 0 ? 2 : 0) }}
            </b>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      packageDetails: {},
      packageId: '',
    };
  },
  computed: {
    ...mapState(['isAuthenticated', 'user']),
  },
  async created() {
    this.packageId = this.$route.query.packageId;

    if (!this.packageId) {
      this.$router.push('/packages');
    }

    await this.fetchPackage();
  },
  methods: {
    async fetchPackage() {
      try {
        const packagesResponse = await this.$axios.post('/api/package-list-based-on-id', {
          api_token: localStorage.getItem('userToken'),
          paf_product_id: this.packageId,
        });

        this.packageDetails = packagesResponse.data.output[0];
      } catch (error) {
        console.error('Error fetching package:', error);
      }
    },
    async createPaymentRequest() {
      try {
        // Call your Laravel API
        const response = await this.$axios.post('http://lbp.test/api/payment/request', {
          user_id: 1,
          package_id: 1,
        });

        if (response.data && response.data.url) {
          // Redirect to the payment page
          window.location.href = response.data.url;
        } else {
          console.error('Payment URL not found in response:', response);
        }
      } catch (error) {
        console.error('Error creating payment request:', error);
      }
    }
  }
};
</script>
