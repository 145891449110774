import { CONSTANT } from "@/constants/constants";

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber || !phoneNumber.length) {
    return '-';
  }
  const countryCode = phoneNumber.slice(0, 3);
  const maskedSection = '****';
  const lastDigits = phoneNumber.slice(-3);

  return `+${countryCode}${maskedSection}${lastDigits}`;
}

export function yearsAgo(startYear) {
  const currentYear = new Date().getFullYear();
  const yearsDifference = currentYear - startYear;

  if (yearsDifference === 0) {
    return 'this year';
  } else if (yearsDifference === 1) {
    return '1 year ago';
  } else {
    return `${yearsDifference} years ago`;
  }
}

export function getUserProfileImage(image) {
  return image
    ? `${CONSTANT.IMAGE_URL}/imagesProfile/${image}`
    : CONSTANT.DEFAULT_IMAGE;
}

export function getPropertyImage(image) {
  return image
    ? `${CONSTANT.IMAGE_URL}/imagesPropertyActualUnit/${image}`
    : CONSTANT.DEFAULT_IMAGE;
}

export function paginatedPages(currentPage, totalPages, startPage = 1) {
  let endPage = totalPages;

  if (totalPages > 10) {
    if (currentPage <= 6) {
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  let pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  return pages;
}
