<template>    
    <LoaderCust :isLoading="isLoading" />
    <section class="property-se">
			<div class="container">
				<div class="property-title-bar">
					<div class="comman-title mb-0">
						<h3><a href="#"><i class="fa fa-angle-left"></i></a> Create Project Listing</h3>
					</div>
					<div class="progress-bar-box">
						<div>3/4</div>
						<div class="progress">
							<div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							 
							<div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
							<div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
							<!--<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>							
							<div class="progress-bar" role="progressbar" style="width: 20%" aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>-->							
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-2">
						<div class="property-sidebar">
							<ul>
                                <li><router-link :to="`/post-project-listing/${itemId}`">Step 1</router-link></li>
                                <li><router-link :to="`/post-project-listing-2/${itemId}`">Step 2</router-link></li>
                                <li><router-link :to="`/post-project-listing-3/${itemId}`" class="active">Step 3</router-link></li>
                                <li><router-link :to="`/post-project-listing-4/${itemId}`">Step 4</router-link></li>
							</ul>
						</div>
					</div>
					<div class="col-md-10">
						<div class="property-box">
                            <form @submit.prevent="submitPost">
                                <div class="property-inner-box">
                                    <div class="comman-title">
                                        <h3>Pricing Details Terms & Payment Options <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Pricing Details Terms & Payment Options"></i></h3>
                                    </div>								
                                    <div class="row row-cols-2">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Listing Price</label>
                                                <input type="text" class="form-control" placeholder="FR Lowest" v-model="form.listing_price_from">
                                                <span  class="error" v-if="errors.listing_price_from">{{ errors.listing_price_from }}</span>
                                            </div>
                                        </div>									 
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Listing Price</label>
                                                <input type="text" class="form-control" placeholder="To Highest" v-model="form.listing_price_to">
                                                <span  class="error" v-if="errors.listing_price_to">{{ errors.listing_price_to }}</span>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Discount From Listing Sale Price (%)</label>
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    id="numberInput"
                                                    v-model="form.listing_price_discount"
                                                    :min="0"
                                                    :max="100"
                                                    @input="validateInput_d1"
                                                    @keypress="handleKeypress"
                                                    placeholder="Discount From Listing Sale Price (%)"
                                                    />
                                                    <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
                                                    <span  class="error" v-if="errors.listing_price_discount">{{ errors.listing_price_discount }}</span>
                                                <!-- <input type="number" min="0" max="100" class="form-control" placeholder="Discount From Listing Sale Price (%)" v-model="form.listing_price_discount"> -->
                                                <!-- <span  class="error" v-if="errors.listing_price_discount">{{ errors.listing_price_discount }}</span> -->
                                                <!-- <select class="form-control">
                                                    <option>Discount From Listing Sale Price (%)</option>
                                                </select>											 -->
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Rebate From SP&A Price (%)</label>
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    id="numberInput"
                                                    v-model="form.listing_price_rebate"
                                                    :min="0"
                                                    :max="100"
                                                    @input="validateInput_d2"
                                                    @keypress="handleKeypress_d2"
                                                    placeholder="Rebate From SP&A Price (%)"
                                                    />
                                                    <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
                                                    <span  class="error" v-if="errors.listing_price_rebate">{{ errors.listing_price_rebate }}</span>
                                                <!-- <input type="number" min="0" max="100" class="form-control" placeholder="Rebate From SP&A Price (%)" v-model="form.listing_price_rebate"> -->
                                                <!-- <span  class="error" v-if="errors.listing_price_rebate">{{ errors.listing_price_rebate }}</span> -->
                                                <!-- <select class="form-control">
                                                    <option>Rebate From SP&A Price (%)</option>
                                                </select>											 -->
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Payment Mode Acceptance</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Cheque-Bank-Transfer" id="Cheque-Bank-Transfer">
                                                    <label class="form-check-label w-100" for="Cheque-Bank-Transfer">
                                                        Cheque/Bank Transfer 
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Debit-Credit-Card" id="Debit-Credit-Card">
                                                    <label class="form-check-label w-100" for="Debit-Credit-Card">
                                                        Debit/Credit Card <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text"></i>
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="eWallet" id="eWallet">
                                                    <label class="form-check-label w-100" for="eWallet">
                                                        eWallet <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text"></i>
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.mode_of_payment" value="Digital-Currency"  id="Digital-Currency">
                                                    <label class="form-check-label w-100" for="Digital-Currency">
                                                        Digital Currency <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Sale Package Offer</label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="ABSD-Rebate" id="ABSD-Rebate">
                                                    <label class="form-check-label w-100" for="ABSD-Rebate">
                                                        ABSD Rebate
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="Stay-Then-Pay" id="Stay-Then-Pay">
                                                    <label class="form-check-label w-100" for="Stay-Then-Pay">
                                                        Stay-Then-Pay <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text"></i>
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="Deferred-Payment-Scheme-DPS" id="Deferred-Payment-Scheme-DPS">
                                                    <label class="form-check-label w-100" for="Deferred-Payment-Scheme-DPS">
                                                        Deferred Payment Scheme (DPS) <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text"></i>
                                                    </label>
                                                </div>	
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" v-model="form.sale_package_offer" value="Experiential-Leasing-Scheme-Rent-To-Own" id="Experiential-Leasing-Scheme-Rent-To-Own">
                                                    <label class="form-check-label w-100" for="Experiential-Leasing-Scheme-Rent-To-Own">
                                                        Experiential Leasing Scheme (Rent-To-Own) <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>								 			
                                </div>
                                <div class="property-inner-box">
                                    <div class="comman-title">
                                        <h3>Universal Listing Feature <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Universal Listing Feature"></i></h3>
                                    </div>
                                    <p>Property Developer (Marketing Team) will be able to submit or receive requests to assign this project to multiple Estate Agents with our digital-appointment widget, enhancing the reach and accelerating speed-to-market via the entire agency network with leveraged resources, amplifying the success rate while maintaining control of the original Project micro-webpage.</p>
                                    <div class="form-group">
                                        <label>Sale Package Offer</label>

                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="2" id="Assign-To-Recommended-Estate-Agent">
                                            <label class="form-check-label w-100" for="Assign-To-Recommended-Estate-Agent">
                                                Assign To Recommended Estate Agents (Area/Project Specialist)
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="3" id="Find-My-Own">
                                            <label class="form-check-label w-100" for="Find-My-Own">
                                                Find My Own/Co-Broking Estate Agent
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" v-model="form.hybrid_listing_type" value="1" id="Attend-To-Inquiry">
                                            <label class="form-check-label w-100" for="Attend-To-Inquiry">
                                                Attend To Enquiry By Internal Marketing Team
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="property-inner-box">
                                    <div class="comman-title">
                                        <h3>Unit Layout 
                                            <!-- <a href="#" class="btn-custom">Add Unit Layout <img src="images/plus-circal.svg" alt=""></a> -->
                                        </h3>									
                                    </div>
                                    <div class="comman-title">
                                        <h4>Configuration</h4>
                                    </div>
                                    <div class="listofunitdata ">
                                        <table class="table border text-center table-bordered align-middle">
                                            <tr>
                                                <th>Unit Layout Name</th>
                                                <th>Unit Size</th>
                                                <th>Listing Price</th>
                                                <th>Action</th>
                                            </tr>
                                            <tr v-for="unit_layoutData in unit_layoutList" :key="unit_layoutData.value" :value="unit_layoutData.value">
                                                <td>{{ unit_layoutData.unit_layout_name }}</td>
                                                <td>{{ unit_layoutData.unit_size }}</td>
                                                <td>{{ unit_layoutData.listing_price }}</td>
                                                <td>
                                                    <button class="btn-small-custom btn-theme" type="button"><i class="fa fa-pencil"></i></button>&nbsp;
                                                    <button class="btn-small-custom btn-red deleteUnitLayout" :id="unit_layoutData.id" type="button"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div>
                                        <form @submit.prevent="submitPostUnitLayout">
                                            <div class="row row-cols-3">
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Unit Layout Name</label>
                                                        <input type="text" class="form-control" placeholder="Unit Layout Name" v-model="form.unit_layout_name">
                                                        <span  class="error" v-if="errors.unit_layout_name">{{ errors.unit_layout_name }}</span>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Unit Size (SQF/SQM)</label>
                                                        <input type="text" class="form-control" placeholder="FR Min Floor Area/Built-Up" v-model="form.unit_size">
                                                        <span  class="error" v-if="errors.unit_size">{{ errors.unit_size }}</span>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Listing Price</label>
                                                        <input type="text" class="form-control" placeholder="FR Lowest" v-model="form.listing_price">
                                                        <span  class="error" v-if="errors.listing_price">{{ errors.listing_price }}</span>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Rooms</label>
                                                        <input type="text" class="form-control" placeholder="10" v-model="form.no_of_rooms">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Baths</label>
                                                        <input type="text" class="form-control" placeholder="10" v-model="form.no_of_baths">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Car Park Lots</label>
                                                        <input type="text" class="form-control" placeholder="10" v-model="form.no_car_park_lots">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Total Units</label>
                                                        <input type="text" class="form-control" placeholder="10" v-model="form.total_units">
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Layout Type</label>
                                                        <select id="layout_type_id" v-model="form.layout_type_id" class="form-control">
                                                            <option value="0" disabled selected>Layout Type</option>
                                                            <option v-for="option in optionsLayoutTypeList" :key="option.value" :value="option.value">
                                                                {{ option.label }}
                                                            </option>
                                                        </select>	
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Floor Level</label>
                                                        <select id="floor_level_id" v-model="form.floor_level_id" class="form-control">
                                                            <option value="0" disabled selected>Floor Level</option>
                                                            <option v-for="option in optionsFloorLevelList" :key="option.value" :value="option.value">
                                                                {{ option.label }}
                                                            </option>
                                                        </select>	
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Main View</label>
                                                        <select id="main_view_id" v-model="form.main_view_id" class="form-control">
                                                            <option value="0" disabled selected>Main View</option>
                                                            <option v-for="option in optionsMainList" :key="option.value" :value="option.value">
                                                                {{ option.label }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="form-group">
                                                        <label>Unit Layout Features</label>
                                                        <select class="form-control">
                                                            <option>10+</option>
                                                        </select>	
                                                    </div>
                                                </div>
                                                <div class="col" style="display: none">
                                                    <div class="form-group">
                                                        <label></label>
                                                        <button class="btn comman-btn-2 w-100"><b>Add Features</b> <img src="images/plus-circal.svg" alt=""></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="comman-title">
                                                <h4>Enriched Listing (Bonus) - Unit Layout Orientation For FengShui Mapping <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Enriched Listing (Bonus) - Unit Layout Orientation For FengShui Mapping"></i></h4>
                                            </div>

                                            <div class="">
                                                <label>Facing Direction <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text" data-bs-original-title="" title=""></i></label>
                                                <div class="row row-cols-3">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_building_id">
                                                                <option value="0" disabled>Main Block/Building Facing</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_building_id">{{ errors.FSM_property_facing_orientation_building_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_main_entrance_id">
                                                                <option value="0" disabled>Main Door Facing</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_main_entrance_id">{{ errors.FSM_property_facing_orientation_main_entrance_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_property_facing_orientation_main_balcony_window_id">
                                                                <option value="0" disabled>Main Balcony/Window Facing</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_property_facing_orientation_main_balcony_window_id">{{ errors.FSM_property_facing_orientation_main_balcony_window_id }}</span>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="">
                                                <label>Space Sector <i class="fa fa-question-circle" data-bs-toggle="tooltip" data-bs-title="Your Text" data-bs-original-title="" title=""></i></label>
                                                <div class="row row-cols-2">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_main_door_entrance_sector_id">
                                                                <option value="0" disabled>Main Door/Entrance Sector</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_main_door_entrance_sector_id">{{ errors.FSM_space_sectors_main_door_entrance_sector_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_main_living_hall_hall_sector_id">
                                                                <option value="0" disabled>Main Living-Hall Sector</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_main_living_hall_hall_sector_id">{{ errors.FSM_space_sectors_main_living_hall_hall_sector_id }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_kitchen_sector_id">
                                                                <option value="0" disabled>Kitchen Sector</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_kitchen_sector_id">{{ errors.FSM_space_sectors_kitchen_sector_id }}</span>	
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <select class="form-control" v-model="form.FSM_space_sectors_main_master_bedroom_sector_id">
                                                                <option value="0" disabled>Main/Master Room Sector</option>
                                                                <RecursiveDropdown :options="hierarchicalOptions" />
                                                            </select>
                                                            <span  class="error" v-if="errors.FSM_space_sectors_main_master_bedroom_sector_id">{{ errors.FSM_space_sectors_main_master_bedroom_sector_id }}</span>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-md-end">
                                                <!-- <router-link :to="`/post-project-listing-2/${itemId}`" class="btn comman-btn-6">Previous</router-link> -->
                                                <button class="btn btn-custom ms-3" type="submit">Add Unit Layout</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-md-end">
                                    <router-link :to="`/post-project-listing-2/${itemId}`" class="btn comman-btn-6">Previous</router-link>
                                    <button class="btn comman-btn-2 ms-3" type="submit">Next</button>
                                </div>
                            </form>
						</div>
					</div>
				</div>
			</div>
		</section>

</template>
  
  <script>
  import LoaderCust from '@/components/LoaderCust.vue';
  import { mapState } from 'vuex';
  import RecursiveDropdown from '@/components/RecursiveDropdown.vue';
  import { FUNG_SHUI_CARDINAL } from '@/constants/constantsProperty';
  import toastr from 'toastr';
  export default {
    name: 'PostPropertyListingStep3', 
    components: {
        RecursiveDropdown,
        LoaderCust,
    },
    data() {
        return { 
            isLoading: false,    
            errorMessage :'',    
            options: this.transformData(FUNG_SHUI_CARDINAL),   
            optionsLayoutTypeList: [],   
            optionsMainList: [],
            optionsFloorLevelList: [],
            unit_layoutList: [],
            form: {
                main_view_id : '0',
                layout_type_id : '0',
                floor_level_id : '0',
                listing_price_from : '',
                listing_price_to : '',
                listing_price_discount : '',
                listing_price_rebate : '',
                sale_package_offer : [],
                hybrid_listing_type : '1',
                additional_description:'',
                asking_price_sale_rent:'',
                pr_master_furnishing_fitting_id:'',
                mode_of_payment:[],

                FSM_property_facing_orientation_building_id: 0,
                FSM_property_facing_orientation_main_entrance_id: 0,
                FSM_property_facing_orientation_main_balcony_window_id: 0,
                FSM_space_sectors_main_door_entrance_sector_id: 0,
                FSM_space_sectors_main_living_hall_hall_sector_id: 0,
                FSM_space_sectors_kitchen_sector_id: 0,
                FSM_space_sectors_main_master_bedroom_sector_id: 0,
            },            
            errors: {}
        }
    },
    mounted() {
        this.setupEventListeners();
    },
    computed: {
      ...mapState(['isAuthenticated', 'user']),
      hierarchicalOptions() {        
            return this.createHierarchicalOptions(this.options);
        },
      itemId() {
        return this.$route.params.id;
      }
    },
    created() {
        this.fetchOptions();
    },
    methods: {
        validateForm() {
            console.log(this.form.facilities_ids);
             this.errors = {};
             if (this.form.listing_price_from == 0) {
                 this.errors.listing_price_from = 'From Listing Price is required.';
             }
             if (this.form.listing_price_to == 0) {
                 this.errors.listing_price_to = 'From Listing Price is required.';
             }
             if (!this.form.listing_price_discount) {
                 this.errors.listing_price_discount = 'Discount From Listing Sale Price is required.';
             }
             if (!this.form.listing_price_rebate) {
                 this.errors.listing_price_rebate = 'Rebate From SP&A  Price is required.';
             }
            return Object.keys(this.errors).length === 0;
            //return 1
        }, 
        validateUnitLayoutForm() {
            
             this.errors = {};
             if (!this.form.unit_layout_name) {
                 this.errors.unit_layout_name = 'Unit Layout Name is required.';
             }
             if (!this.form.listing_price) {
                 this.errors.listing_price = 'Listing Price is required.';
             }
             if (!this.form.unit_size) {
                 this.errors.unit_size = 'Listing Price Price is required.';
             }
            return Object.keys(this.errors).length === 0;
            //return 1
        }, 
        async submitPost() {
            
            //this.$router.push('/post-property-listing-3/'+this.itemId);         
            if (this.validateForm()) {
                //var qualify_for_foreigner = this.form.qualify_for_foreigner == true ? 1 : 0
                const token = localStorage.getItem('userToken');
                if (this.isLoading) return;
                this.isLoading = true;
                try {
                    
                    await this.$axios.post('api/update-pricing-and-other-details', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                        
                        listing_price_from : this.form.listing_price_from,
                        listing_price_to : this.form.listing_price_to,
                        listing_price_discount : this.form.listing_price_discount,
                        listing_price_rebate : this.form.listing_price_rebate,
                        mode_of_payment : this.form.mode_of_payment,
                        sale_package_offer : this.form.sale_package_offer,
                        hybrid_listing_type : this.form.hybrid_listing_type
                    })
                    .then(response => {
                        console.log('API response:', response);
                        toastr.success(response.data.message, 'Success', {progressBar: true, closeButton: true});
                        this.$router.push('/post-project-listing-4/'+this.itemId); 
                        //this.submitPostUnitLayout();
                    })
                    // this.submitPostUnitLayout();
                    

                } catch (error) {
                    
                    toastr.error('Something went wrong in API',{progressBar: true, closeButton: true})

                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }
                } finally {
                    this.isLoading = false;
                }
            }
        }, 
        async submitPostUnitLayout() {
            // if(!this.unit_layoutList){
            //     this.validateUnitLayoutForm()
            // }
            if (this.validateUnitLayoutForm()) {
                const token = localStorage.getItem('userToken');                
                try {
                    const response = await this.$axios.post('api/add-update-unit-layout', {
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                        
                        
                        project_unit_layout_id: 0,
                        unit_layout_name: this.form.unit_layout_name ? this.form.unit_layout_name : '',
                        unit_size : this.form.unit_size ? this.form.unit_size : '',
                        listing_price : this.form.listing_price ? this.form.listing_price : 0,
                        no_of_rooms : this.form.no_of_rooms ? this.form.no_of_rooms : 0,
                        no_of_baths : this.form.no_of_baths ? this.form.no_of_baths : 0,
                        no_car_park_lots : this.form.no_car_park_lots ? this.form.no_car_park_lots : 0,
                        total_units : this.form.total_units ? this.form.total_units : 0,
                        layout_type_id : this.form.layout_type_id ? this.form.layout_type_id : 0,
                        floor_level_id : this.form.floor_level_id ? this.form.floor_level_id : 0,
                        main_view_id : this.form.main_view_id ? this.form.main_view_id : 0,
                        feature_ids : '0',
                        // feature_ids : this.form.feature_ids,
                        FSM_property_facing_orientation_building_id:this.form.FSM_property_facing_orientation_building_id ? this.form.FSM_property_facing_orientation_building_id : 0,
                        FSM_property_facing_orientation_main_entrance_id : this.form.FSM_property_facing_orientation_main_entrance_id ? this.form.FSM_property_facing_orientation_main_entrance_id : 0,
                        FSM_property_facing_orientation_main_balcony_window_id : this.form.FSM_property_facing_orientation_main_balcony_window_id ? this.form.FSM_property_facing_orientation_main_balcony_window_id : 0,
                        FSM_space_sectors_main_door_entrance_sector_id : this.form.FSM_space_sectors_main_door_entrance_sector_id ? this.form.FSM_space_sectors_main_door_entrance_sector_id : 0,
                        FSM_space_sectors_main_living_hall_hall_sector_id : this.form.FSM_space_sectors_main_living_hall_hall_sector_id ? this.form.FSM_space_sectors_main_living_hall_hall_sector_id : 0,
                        FSM_space_sectors_kitchen_sector_id : this.form.FSM_space_sectors_kitchen_sector_id ? this.form.FSM_space_sectors_kitchen_sector_id : 0,
                        FSM_space_sectors_main_master_bedroom_sector_id : this.form.FSM_space_sectors_main_master_bedroom_sector_id ? this.form.FSM_space_sectors_main_master_bedroom_sector_id : 0,
                    });
                    
                    toastr.success(response.data.message, 'Success', {progressBar: true, closeButton: true});
                    this.unit_layoutList = response.data.output
                    //var project_listing_id = response.data.output.id
                    //this.$router.push('/post-project-listing-4/'+this.itemId);         

                } catch (error) {                    

                    toastr.error('Something went wrong in API',{progressBar: true, closeButton: true})
                    if (error.response && error.response.data) {
                        this.errors = this.extractErrors(error.response.data.errors || {});
                    } else {
                        console.error('An unexpected error occurred:', error);
                    }

                } finally {
                    this.isLoading = false;
                }
            }
            
        }, 
        extractErrors(errors) {
            // Extract the first error message from each field's errors array
            const formattedErrors = {};
            for (const [field, messages] of Object.entries(errors)) {
                if (Array.isArray(messages) && messages.length > 0) {
                formattedErrors[field] = messages[0]; // Get the first error message
                }
            }
            return formattedErrors;
        }, 
        validateInput_d1() {
            if (this.form.listing_price_discount === '') {
                this.errorMessage = '';
                return;
            }
            const num = parseFloat(this.form.listing_price_discount);
            if (isNaN(num) || num < 0 || num > 100) {
                this.errorMessage = `Please enter a number between 0 and 100.`;
            } else {
                this.errorMessage = '';
            }
        },
        validateInput_d2() {
            if (this.form.listing_price_rebate === '') {
                this.errorMessage = '';
                return;
            }
            const num = parseFloat(this.form.listing_price_rebate);
            if (isNaN(num) || num < 1 || num > 100) {
                this.errorMessage = `Please enter a number between 0 and 100.`;
            } else {
                this.errorMessage = '';
            }
        },
        handleKeypress(event) {
            const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
            if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },        
        handleKeypress_d2(event) {
            const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'];
            if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key)) {
                event.preventDefault();
            }
        },    
        transformData(data) {
            const map = {};
            const result = [];
    
            data.forEach(item => {
            map[item.id] = { ...item, children: [] };
            });
    
            data.forEach(item => {
            if (item.parent_id === 0) {
                result.push(map[item.id]);
            } else {
                map[item.parent_id].children.push(map[item.id]);
            }
            });
    
            return result;
        },
        createHierarchicalOptions(data) {
            return data.map(option => this.createOptionGroup(option));
        },
        createOptionGroup(option) {
                if (!option.children || option.children.length === 0) {
                    return {
                        type: 'option',
                        id: option.id,
                        label: option.name,
                        depth: option.depth,
                    };
                }
                return {
                    type: 'optgroup',
                    label: option.name,
                    depth: option.depth,
                    children: option.children.map(child => this.createOptionGroup(child))
                };
        },    
        async fetchOptions() {
            this.isLoading = true;
            const token = localStorage.getItem('userToken');

            // Get Layout type list
            try {
                const response = await this.$axios.post('api/pr-layout-features-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsLayoutTypeList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Main View
            try {
                const response = await this.$axios.post('api/pr-main-view-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 0,
                    show_in_property:1,
                    last_record_number : 0                     
                });
                this.optionsMainList = response.data.output.map(item => ({
                    value: item.id,
                    label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }

            // Get Floor level
            try {
                const response = await this.$axios.post('api/pr-floor-levels-list',
                {                    
                    parent_id : "0",
                    keyword : "", 
                    show_in_project: 1,
                    show_in_property:0,
                    last_record_number : 0                     
                });
                this.optionsFloorLevelList = response.data.output.map(item => ({
                value: item.id,
                label: item.name
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
            // If Edit
            if(this.itemId > 0){                    
                try {
                    const response = await this.$axios.post('api/project-detail',
                    {                    
                        user_id :this.user.id,
                        api_token: token,
                        project_listing_id:this.itemId,                    
                    });
                    this.editData = response.data.output[0];
                    this.unit_layoutList = this.editData.unit_layout
                    this.form.listing_price_from = this.editData.listing_price_from;
                    this.form.listing_price_to = this.editData.listing_price_to;
                    this.form.listing_price_discount = this.editData.listing_price_discount;
                    this.form.listing_price_rebate = this.editData.listing_price_rebate;
                    if (this.editData.mode_of_payment) {
                        this.form.mode_of_payment = JSON.parse(this.editData.mode_of_payment);
                    }
                    if (this.editData.sale_package_offer) {
                        this.form.sale_package_offer = JSON.parse(this.editData.sale_package_offer);
                    }


                    this.form.unit_layout_name= this.editData.unit_layout_name,
                    this.form.unit_size = this.editData.unit_size,
                    this.form.listing_price = this.editData.listing_price,
                    this.form.no_of_rooms = this.editData.no_of_rooms,
                    this.form.no_of_baths = this.editData.no_of_baths,
                    this.form.no_car_park_lots = this.editData.no_car_park_lots,
                    this.form.total_units = this.editData.total_units,
                    this.form.layout_type_id = this.editData.layout_type_id ? this.editData.layout_type_id : 0,
                    this.form.floor_level_id = this.editData.floor_level_id ? this.editData.floor_level_id : 0,
                    this.form.main_view_id = this.editData.main_view_id ? this.editData.main_view_id : 0,
                    
                    this.form.FSM_property_facing_orientation_building_id = this.editData.FSM_property_facing_orientation_building_id ? this.editData.FSM_property_facing_orientation_building_id : 0;
                    this.form.FSM_property_facing_orientation_main_entrance_id = this.editData.FSM_property_facing_orientation_main_entrance_id ? this.editData.FSM_property_facing_orientation_main_entrance_id : 0;
                    this.form.FSM_property_facing_orientation_main_balcony_window_id = this.editData.FSM_property_facing_orientation_main_balcony_window_id ? this.editData.FSM_property_facing_orientation_main_balcony_window_id : 0;

                    this.form.FSM_space_sectors_main_door_entrance_sector_id = this.editData.FSM_space_sectors_main_door_entrance_sector_id ? this.editData.FSM_space_sectors_main_door_entrance_sector_id : 0;
                    this.form.FSM_space_sectors_main_living_hall_hall_sector_id = this.editData.FSM_space_sectors_main_living_hall_hall_sector_id ? this.editData.FSM_space_sectors_main_living_hall_hall_sector_id : 0;
                    this.form.FSM_space_sectors_kitchen_sector_id = this.editData.FSM_space_sectors_kitchen_sector_id ? this.editData.FSM_space_sectors_kitchen_sector_id : 0;
                    this.form.FSM_space_sectors_main_master_bedroom_sector_id = this.editData.FSM_space_sectors_main_master_bedroom_sector_id ? this.editData.FSM_space_sectors_main_master_bedroom_sector_id : 0;
                    //this.form.hybrid_listing_type = this.editData.hybrid_listing_type;
                    this.isLoading = false;

                } catch (error) {
                    console.error('Error fetching options:', error);
                }
            }
            // If Edit
        },
        setupEventListeners() {
            const self = this; // Save reference to `this`
            
            window.$(document).on('click', '.deleteUnitLayout', function() {        
                const deleteType = window.$(this).data('deletetype');
                const id = window.$(this).attr('id');
                window.Swal.fire({
                title: 'Are you sure?',
                text: `Delete ${deleteType}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.deleteAddtional(deleteType, id);
                }
                });
            });
        },
    }
  }
  </script>
  
  <style scoped>
  /* Add styles specific to the About Us page here */
  </style>
  